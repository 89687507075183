import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormArray,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Sort } from '../interfaces/sort';
import { LoaderComponent } from './loader.component';
import { CustomFilter } from '../interfaces/custom-filter';
import { CustomFilterType } from '../enums/custom-filter-type';
import { VersionDirective } from '../directives/version.directive';

@Component({
  selector: 'app-data-filter-v2',
  templateUrl: './data-filter-v2.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    LoaderComponent,
    NgClass,
    NgFor,
    NgIf,
    TranslateModule,
    VersionDirective,
  ],
})
export class DataFilterV2Component {
  public filterForm: FormGroup;
  public loading: boolean;
  public readonly filters = input<boolean>(false);
  public readonly isHorizontal = input<boolean>(false);
  public readonly hasSelectAll = input<boolean>(false);
  public readonly hasShowMore = input<boolean>(false);
  public readonly searchLabel = input<string>(null);
  public readonly sortLabel = input<string>(null);
  public readonly sortItems = input<Sort[]>([]);
  public readonly customFilters = input<CustomFilter[]>([]);
  public readonly filterChanged = output<any>();

  public readonly sort = input<Sort>(this.sortItems()[0]);
  public readonly search = input<string>();

  showMore: { [key: number]: boolean } = {}; // Track expanded checkboxes per filter group

  constructor(private formBuilder: FormBuilder) {
    this.createFilterForm();
  }

  private createFilterForm() {
    this.filterForm = this.formBuilder.group({
      sort: [
        this.sortItems()?.length > 0 ? this.sortItems()[0] : null,
        Validators.required,
      ],
      search: [''],
      customFilters: this.formBuilder.array([]),
    });
    this.filterForm.get('sort').setValue(this.sort());
    this.filterForm.valueChanges.subscribe(() => {
      this.updateFilter();
    });
  }

  toggleShowMore(index: number) {
    this.showMore[index] = !this.showMore[index];
  }

  selectAll(groupIndex: number) {
    const filtersArray = this.getFiltersArray(groupIndex);

    if (!filtersArray) return;

    const allChecked = filtersArray.controls.every((control) => control.value);
    filtersArray.controls.forEach((control) => control.setValue(!allChecked));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customFilters'] && this.customFilters()) {
      const previousFilters = changes['customFilters'].previousValue;
      const currentFilters = changes['customFilters'].currentValue;

      if (!this.areFiltersEqual(previousFilters, currentFilters)) {
        this.buildForm();
      }
    }

    if (changes['sortItems'] && this.sortItems()?.length > 0) {
      if (!this.filterForm.get('sort').value) {
        this.filterForm.get('sort').setValue(this.sortItems()[0]);
      }
    }
  }

  private areFiltersEqual(
    previous: CustomFilter[],
    current: CustomFilter[],
  ): boolean {
    if (!previous || !current || previous.length !== current.length) {
      return false;
    }

    return previous.every((prevFilter, index) => {
      const currFilter = current[index];
      return (
        prevFilter.title === currFilter.title &&
        prevFilter.type === currFilter.type &&
        prevFilter.filters.length === currFilter.filters.length &&
        prevFilter.filters.every((prevItem, i) => {
          const currItem = currFilter.filters[i];
          return (
            prevItem.label === currItem.label &&
            prevItem.value === currItem.value &&
            prevItem.key === currItem.key
          );
        })
      );
    });
  }

  getFiltersArray(index: number): FormArray {
    return (this.filtersArray.at(index) as FormGroup).get(
      'filters',
    ) as FormArray;
  }

  get filtersArray(): FormArray {
    return this.filterForm.get('customFilters') as FormArray;
  }

  private buildForm(): void {
    if (this.filtersArray.length === this.customFilters()?.length) return;
    while (this.filtersArray.length) {
      this.filtersArray.removeAt(0);
    }
    this.customFilters()?.forEach((customFilter) => {
      if (!customFilter.filters) {
        console.warn(
          'Custom filter is missing the "filters" property:',
          customFilter,
        );
        return;
      }

      let filtersControl;
      if (customFilter.type === CustomFilterType.RADIO) {
        filtersControl = this.formBuilder.control('all');
      } else {
        filtersControl = this.formBuilder.array(
          customFilter.filters.map((filter) =>
            this.formBuilder.control(filter.value),
          ),
        );
      }

      const filterGroup = this.formBuilder.group({
        title: [customFilter.title],
        type: [customFilter.type],
        filters: filtersControl,
      });

      this.filtersArray.push(filterGroup);
    });
  }

  updateFilter() {
    const customFilters: CustomFilter[] = this.filtersArray.controls.map(
      (group: FormGroup, index) => {
        const formFilters = group.get('filters');
        const originalFilters = this.customFilters()[index]?.filters ?? [];
        const isRadio = group.get('type').value === CustomFilterType.RADIO;

        return {
          title: group.get('title').value,
          type: group.get('type').value,
          groupName: group.get('groupName')?.value,
          filters: isRadio
            ? originalFilters.map((f) => ({
                ...f,
                value: group.get('value')?.value === f.key,
              }))
            : originalFilters.map((f, i) => ({
                ...f,
                value: formFilters.value[i],
              })),
        };
      },
    );

    this.filterChanged.emit({
      search: this.filterForm.get('search').value,
      sort: this.filterForm.get('sort').value,
      customFilters,
    });
  }

  public compareSort(sort1: Sort, sort2: Sort): boolean {
    //FIX THIS
    return (
      sort1?.field === sort2?.field &&
      sort1?.label === sort2?.label &&
      sort1?.direction === sort2?.direction
    );
  }
}
