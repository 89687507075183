<div [ngClass]="{ container: getIsCustomer() }">
  <div *appVersion="1" [ngClass]="{ 'tabs-content': getIsCustomer() }">
    <section class="updates">
      <div class="updates__head flex flex-row">
        <h2>{{ 'projects.detail.update.list.title' | translate }}</h2>
        <a
          (click)="newUpdate()"
          class="btn btn-xlg"
          appAccess
          role="manager"
          [project]="project"
          >{{ 'projects.detail.update.list.create' | translate }}</a
        >
      </div>

      <div
        class="updates__list"
        infiniteScroll
        [scrollWindow]="true"
        [infiniteScrollContainer]="'div.app__content'"
        [fromRoot]="true"
        (scrolled)="loadMoreUpdates()"
      >
        <app-default-updates-tile
          [isCustomer]="isCustomer"
          *ngFor="let update of updates"
          [update]="update"
          [confirmDelete]="confirmDelete"
        ></app-default-updates-tile>

        <div class="update" *ngIf="project">
          <div class="update__timeline">
            <div class="date">
              {{ project.startDate | dateType: project.startType : 'short' }}
            </div>
          </div>
          <div class="update__box update__box--start">
            <div
              [inlineSVG]="'/assets/img/icons/flag.svg'"
              class="inline-svg__item flag-image"
            ></div>
            {{ 'projects.detail.update.list.start' | translate }}
          </div>
        </div>
      </div>

      <app-loader *ngIf="loading"></app-loader>
    </section>
  </div>

  <div *appVersion="2">
    <div class="bouwapp-header">
      <div class="bouwapp-header__text">
        <h1>{{ 'projects.detail.update.list.title' | translate }}</h1>
      </div>
      <div class="bouwapp-header__buttons">
        <a
          (click)="newUpdate()"
          class="secondary"
          appAccess
          role="manager"
          [project]="project"
        >
          <span
            class="icon"
            [inlineSVG]="'/assets/icons/add--white.svg'"
          ></span>
          <span>{{ 'projects.detail.update.list.create' | translate }}</span></a
        >
      </div>
    </div>

    <app-data-filter-v2
      *appVersion="2"
      (filterChanged)="updateFilter($event)"
      [customFilters]="transformToFilters()"
      [searchLabel]="'projects.detail.update.list.search'"
    >
      <div
        class="updates__list"
        infiniteScroll
        [scrollWindow]="true"
        [infiniteScrollContainer]="'div.app__content'"
        [fromRoot]="true"
        (scrolled)="loadMoreUpdates()"
      >
        <p *ngIf="updates && updates.length">
          {{
            'projects.detail.update.list.result.title'
              | translate: { updates: totalItems }
          }}
        </p>

        <app-default-updates-tile
          [isCustomer]="isCustomer"
          *ngFor="let update of updates"
          [update]="update"
          [confirmDelete]="confirmDelete"
        ></app-default-updates-tile>

        <app-loader *ngIf="loading"></app-loader>

        <div class="no-results" *ngIf="updates && updates.length === 0">
          {{ 'projects.detail.update.list.empty' | translate }}
        </div>
      </div>
    </app-data-filter-v2>
  </div>
</div>

<app-confirm-delete
  #confirmDelete
  (confirmed)="deleteConfirmed($event)"
></app-confirm-delete>
<app-update-picker
  [projects]="projects"
  [customers]="customers"
></app-update-picker>
