import { Component, forwardRef, input, viewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import * as moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from './date-picker.component';
import { SwitchOptionComponent } from './switch-option.component';
import { DatePipe, NgIf } from '@angular/common';
import { SwitchComponent } from './switch.component';
import { VersionDirective } from '../directives/version.directive';
import { ModalComponent } from './modal.component';
import { DatePickerV2Component } from './date-picker-v2.component';

@Component({
  selector: 'app-period',
  templateUrl: 'period.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PeriodComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PeriodComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SwitchComponent,
    NgIf,
    SwitchOptionComponent,
    DatePickerComponent,
    DatePickerV2Component,
    TranslateModule,
    VersionDirective,
    ModalComponent,
    DatePipe,
  ],
})
export class PeriodComponent implements ControlValueAccessor {
  form: FormGroup;
  public type: string = 'week';
  public oldType: string;
  private readonly modal = viewChild<ModalComponent>(ModalComponent);

  propagateChange = (_: any) => {};
  propagateTouch = (_: any) => {};

  public readonly hasWeek = input<boolean>(true);
  public readonly hasDay = input<boolean>(true);
  public readonly hasTotal = input<boolean>(true);
  public readonly hasMonth = input<boolean>(true);
  public readonly hasCustom = input<boolean>(true);
  public readonly hasButton = input<boolean>(false);

  constructor(private formBuilder: FormBuilder) {
    this.createForm();
  }

  /**
   * @param value
   */
  writeValue(value: any): void {
    this.type = value;
    this.form.patchValue(value);
  }

  /**
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  modalClosed() {
    this.type = this.oldType;
  }

  /**
   * @param control
   * @returns {any}
   */
  validate(control: FormControl): any {
    return this.form.get('type').value !== 'custom' ||
      moment(this.form.get('start').value).isSameOrBefore(
        this.form.get('end').value,
      )
      ? null
      : { invalidPeriod: true };
  }

  /**
   * Create the form
   */
  private createForm() {
    this.form = this.formBuilder.group({
      type: ['week'],
      start: [moment().subtract(7, 'days').format()],
      end: [moment().format()],
    });

    this.form.valueChanges.subscribe((value) => {
      this.oldType = this.type;
      this.type = value.type;

      if (this.type === 'custom') {
        this.modal().open();
      }

      switch (this.type) {
        case 'day':
          value.end = moment().format();
          value.start = moment();
          break;
        case 'week':
          value.end = moment().format();
          value.start = moment().subtract(7, 'days').format();
          break;
        case 'month':
          value.end = moment().format();
          value.start = moment().subtract(30, 'days').format();
          break;
        case 'total':
          value.start = null;
          value.end = null;
          break;
      }

      if (!this.hasButton || this.type !== 'custom') {
        this.propagateChange(value);
        this.propagateTouch(value);
      }
    });
  }

  public saveChanges() {
    this.propagateChange(this.form.value);
    this.propagateTouch(this.form.value);
    this.modal().close();
  }
}
