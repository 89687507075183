import { Component, OnInit, viewChild } from '@angular/core';
import * as moment from 'moment';
import { UpdatePickerComponent } from 'src/app/components/update-helpers/update-picker.component';
import { Customer } from 'src/app/interfaces/customer';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { AccessService } from 'src/app/services/access.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { UpdateService } from 'src/app/services/update.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { SecurityVoter } from 'src/app/security/security-voter';
import { UpdateStatus } from 'src/app/enums/update-status';
import { ActivatedRoute, Router } from '@angular/router';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { LoaderComponent } from 'src/app/components/loader.component';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ConfirmDeleteComponent } from 'src/app/components/confirm-delete.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DateTypePipe } from 'src/app/pipes/date-type.pipe';
import { UpdatesTileComponent } from './tile.component';
import { AccessDirective } from 'src/app/directives/access.directive';
import { VersionDirective } from 'src/app/directives/version.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { Sort } from 'src/app/interfaces/sort';
import { ThemeService } from 'src/app/services/theme.service';
import { ProjectStatus } from 'src/app/enums/project-status';
import { FormGroupComponent } from 'src/app/components/form-group.component';
import { DataFilterV2Component } from 'src/app/components/data-filter-v2.component';
import { CustomFilterItem } from 'src/app/interfaces/custom-filter-item';
import { CustomFilter } from 'src/app/interfaces/custom-filter';
import { CustomFilterType } from 'src/app/enums/custom-filter-type';

@Component({
  selector: 'app-default-updates-list',
  templateUrl: 'list.component.html',
  standalone: true,
  imports: [
    NgClass,
    VersionDirective,
    ReactiveFormsModule,
    LoaderComponent,
    NgIf,
    NgForOf,
    TranslateModule,
    InfiniteScrollDirective,
    UpdatePickerComponent,
    ConfirmDeleteComponent,
    DataFilterV2Component,
    AccessDirective,
    InlineSVGModule,
    DateTypePipe,
    UpdatesTileComponent,
    FormGroupComponent,
  ],
})
export class UpdatesListComponent implements OnInit {
  public readonly updatePicker = viewChild<UpdatePickerComponent>(
    UpdatePickerComponent,
  );

  public project?: Project;
  public projects: Project[];
  public customers: Customer[];
  public isCustomer: boolean = false;

  public updates: Update[];

  private perPage: number = 20;

  private page: number = 0;
  private ready = false;

  public totalItems: number;
  public version: number;

  private readonly defaultStatuses = [
    UpdateStatus.PUBLISHED,
    UpdateStatus.SCHEDULED,
    UpdateStatus.CONCEPT,
  ];

  private search = null;
  private statuses = null;
  private startDate = null;
  private endDate = null;
  public statusCount;
  public loading: boolean = false;

  public projectIds: number[] | null;
  public customerIds: number[] | null;

  constructor(
    private projectDataBusService: ProjectDataBusService,
    private updateService: UpdateService,
    private userDataService: UserDataService,
    private accessService: AccessService,
    private router: Router,
    private route: ActivatedRoute,
    private themeService: ThemeService,
  ) {}

  /**
   * Loads till the page is filled, so an user will be able to scroll down to see more
   * @returns {Promise<void>}
   */
  async loadUpdates(): Promise<void> {
    await this.loadMoreUpdates();
  }

  public getIsCustomer() {
    return this.isCustomer;
  }

  public newUpdate() {
    if (this.project) {
      this.router.navigate(['/projects', this.project.slug, 'updates', 'new']);
    }
    this.updatePicker().pick();
  }

  public async getStatusCount() {
    this.statusCount = await this.updateService.getStatusCount(
      this.project || null,
      this.search,
      this.startDate,
      this.endDate,
      this.getIsCustomer() ? this.customerIds : null,
      this.getIsCustomer() ? this.projectIds : null,
    );
  }

  updateFilter(data: any) {
    this.search = data.search;

    this.statuses =
      data.customFilters
        .find(
          (f) =>
            f.title === 'project.list.filter.status.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => filter.key) || [];

    const dateFilters = data.customFilters.find(
      (f) =>
        f.title === 'project.list.filter.date.title' &&
        f.type === CustomFilterType.DATE,
    );
    this.startDate =
      dateFilters?.filters.find((f) => f.key === 'startDate')?.value || null;
    this.endDate =
      dateFilters?.filters.find((f) => f.key === 'endDate')?.value || null;

    this.customerIds =
      data.customFilters
        .find(
          (f) =>
            f.title === 'project.list.filter.customer.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => parseInt(filter.key)) || [];

    this.projectIds =
      data.customFilters
        .find(
          (f) =>
            f.title === 'project.list.projects.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => parseInt(filter.key)) || [];

    if (!this.ready) {
      return;
    }

    this.reset();
    this.loadUpdates();
    this.getStatusCount();
  }

  get allStatuses(): UpdateStatus[] {
    return Object.values(UpdateStatus);
  }

  transformToFilters(): CustomFilter[] {
    const filters: CustomFilter[] = [];
    const statusFilters: CustomFilterItem[] = this.allStatuses.map(
      (status, index) => ({
        label: 'project.list.filter.status.' + status,
        value: this.defaultStatuses.includes(status),
        key: status,
        count: this.statusCount ? this.statusCount[status] : undefined,
      }),
    );

    filters.push({
      title: 'project.list.filter.status.title',
      type: CustomFilterType.CHECKBOX,
      filters: statusFilters,
    });

    filters.push({
      title: 'project.list.filter.date.title',
      type: CustomFilterType.DATE,
      filters: [
        {
          label: 'project.list.filter.date.startdate',
          value: this.startDate,
          key: 'startDate',
        },
        {
          label: 'project.list.filter.date.enddate',
          value: this.endDate,
          key: 'endDate',
        },
      ],
    });

    const customerFilters: CustomFilterItem[] = this.customers.map(
      (customer) => ({
        label: customer.name,
        value: true,
        key: customer.id.toString(),
      }),
    );

    filters.push({
      title: 'project.list.filter.customer.title',
      type: CustomFilterType.CHECKBOX,
      filters: customerFilters,
    });

    if (this.projects) {
      const projectFilters: CustomFilterItem[] = this.projects.map(
        (project) => ({
          label: project.name,
          value: false,
          key: project.id.toString(),
        }),
      );

      filters.push({
        title: 'project.list.projects.title',
        type: CustomFilterType.CHECKBOX,
        filters: projectFilters,
      });
    }

    return filters;
  }

  /**
   * Loads more updates
   * @returns {Promise<void>}
   */
  async loadMoreUpdates(): Promise<void> {
    this.loading = true;
    this.page += 1; // do before a request, so you won't get duplicates

    if (!this.updates || this.updates.length < this.totalItems) {
      let response: any;

      if (this.version == 1) {
        if (this.project) {
          response = await this.updateService.listByProject(
            this.project,
            this.page,
            this.perPage,
          );
        } else {
          const projectUser = await this.userDataService.retrieveProjectUser();
          response = await this.updateService.listUpdates(
            projectUser,
            this.page,
            this.perPage,
          );
        }
      } else {
        if (this.project) {
          response = await this.updateService.listByProjectV2(
            this.project,
            this.page,
            this.perPage,
            'desc',
            this.search,
            this.statuses,
            this.startDate,
            this.endDate,
            false,
          );
        } else {
          const projectUser = await this.userDataService.retrieveProjectUser();
          response = await this.updateService.listUpdatesV2(
            projectUser,
            this.page,
            this.perPage,
            'desc',
            this.search,
            this.statuses,
            this.startDate,
            this.endDate,
            this.customerIds,
            this.projectIds,
            false,
          );
        }
      }

      const result: Update[] = response['hydra:member'];

      if (result != null) {
        if (!this.updates) {
          this.updates = [];
          this.totalItems = response['hydra:totalItems'];
        }

        this.updates = this.updates.concat(result);
      }
    }

    this.loading = false;
  }

  async deleteConfirmed(update: Update): Promise<void> {
    await this.updateService.remove(update);

    const index: number = this.updates.indexOf(update);
    if (index !== -1) {
      this.updates.splice(index, 1);

      this.processUpdates(this.updates);
    }
  }

  /**
   * Checks whether updates should show a year
   * @returns {void}
   */
  private processUpdates(updates: Update[]): void {
    let lastYear: number;

    for (const update of updates) {
      const date = moment(update.publicationDate);
      const year = +date.format('Y');

      if (year !== lastYear) {
        update.year = year;
        lastYear = year;
      }
    }
  }

  public compareSort(sort1: Sort, sort2: Sort): boolean {
    return (
      sort1.field === sort2.field &&
      sort1.label === sort2.label &&
      sort1.direction === sort2.direction
    );
  }

  private reset() {
    this.page = 0;
    this.totalItems = undefined;
    this.updates = undefined;
  }

  async ngOnInit(): Promise<void> {
    this.version = await this.themeService.getVersion();
    this.route.parent.params.subscribe((params) => {
      if (!params['slug']) {
        this.isCustomer = true;
        this.project = null;
        this.getStatusCount();
      } else {
        this.projectDataBusService.projectObservable.subscribe((project) => {
          this.project = project;
          if (null === this.project) {
            this.isCustomer = true;
          }
          this.getStatusCount();
        });
      }
    });

    this.accessService.accessControlList.subscribe((list) => {
      if (list == null) {
        return;
      }

      this.customers = this.accessService.getCustomersFromACL(list);
      this.projectDataBusService.shortListObservable.subscribe((shortList) => {
        if (shortList === null) {
          return;
        }
        this.projects = shortList.filter(
          (item) =>
            SecurityVoter.canEditProject(list, item) === true &&
            item.status !== ProjectStatus.ARCHIVED,
        );
        this.ready = true;

        this.projects.sort(function (projectA, projectB) {
          const projectNameA = projectA.name.toUpperCase();
          const projectNameB = projectB.name.toUpperCase();
          return projectNameA < projectNameB
            ? -1
            : projectNameA > projectNameB
              ? 1
              : 0;
        });
      });
    });

    this.loadUpdates();
  }
}
