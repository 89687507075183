<form [formGroup]="form">
  <div class="period">
    <app-switch formControlName="type">
      <app-switch-option *ngIf="hasWeek()" value="week">{{
        'period.type.week' | translate
      }}</app-switch-option>
      <app-switch-option *ngIf="hasDay()" value="day">{{
        'period.type.day' | translate
      }}</app-switch-option>
      <app-switch-option *ngIf="hasMonth()" value="month">{{
        'period.type.month' | translate
      }}</app-switch-option>
      <app-switch-option *ngIf="hasTotal()" value="total">{{
        'period.type.total' | translate
      }}</app-switch-option>
      <app-switch-option *ngIf="hasCustom()" value="custom">{{
        'period.type.custom' | translate
      }}</app-switch-option>
      <div class="switch__range" *ngIf="form.get('type').value === 'custom'">
        {{ form.value.start | date: 'dd-MM-yyyy' }}
        <span class="switch__range__dash">{{
          'profile.export.period.till' | translate
        }}</span>
        {{ form.value.end | date: 'dd-MM-yyyy' }}
      </div>
    </app-switch>

    <div class="manual" *appVersion="1" [hidden]="type !== 'custom'">
      <span>{{ 'period.from' | translate }}</span>
      <app-date-picker
        formControlName="start"
        [withTime]="false"
        [inline]="true"
      ></app-date-picker>
      <span>{{ 'period.till' | translate }}</span>
      <app-date-picker
        formControlName="end"
        [withTime]="false"
        [inline]="true"
      ></app-date-picker>
    </div>
  </div>
  <app-modal
    (onClose)="modalClosed()"
    [title]="'period.custom_range' | translate"
  >
    <span>{{ 'period.from' | translate }}</span>
    <app-date-picker-v2
      formControlName="start"
      [dateOnly]="true"
    ></app-date-picker-v2>
    <span>{{ 'period.till' | translate }}</span>
    <app-date-picker-v2
      formControlName="end"
      [dateOnly]="true"
    ></app-date-picker-v2>
    <button
      *ngIf="hasButton()"
      (click)="saveChanges()"
      class="button button--m button--flat bg--orange"
    >
      {{ 'period.save_range' | translate }}
    </button>
  </app-modal>
</form>
