<ng-container *appVersion="2">
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1>{{ 'project.detail.statistics.new.title' | translate }}</h1>
    </div>
    <div class="bouwapp-header__buttons">
      <button class="tertiary" (click)="export()">
        {{ 'chart.export.pdf' | translate }}
      </button>
    </div>
  </div>

  <section class="statistics">
    <div class="alert notice">
      <span inlineSVG="/assets/v2/img/icons/info.svg"></span>
      <p>
        <span>{{
          'project.detail.statistics.new.notice.prefix' | translate
        }}</span>
        <span>{{
          'project.detail.statistics.new.notice.message' | translate
        }}</span>
      </p>
    </div>
    <ng-container
      *ngTemplateOutlet="
        stat;
        context: {
          title: 'users',
          number: '50000',
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        stat;
        context: {
          title: 'followers',
          number: 3000,
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        stat;
        context: {
          title: 'engagers',
          number: 250,
        }
      "
    ></ng-container>
  </section>

  <hr />

  <section class="charts">
    <div class="settings" *ngIf="periodControl">
      <app-period
        [hasCustom]="false"
        [formControl]="periodControl"
      ></app-period>
    </div>
    <h2>{{ 'project.detail.statistics.new.users' | translate }}</h2>
    <div class="chart" *ngIf="activeUsersChart">
      <h3>
        {{ 'project.detail.statistics.new.stats.active_users' | translate }}
        <span
          (click)="openModal('active_users')"
          inlineSVG="/assets/v2/img/icons/info.svg"
        ></span>
      </h3>
      <div class="chart__inner">
        <canvas
          baseChart
          [type]="activeUsersChart.config.type"
          [options]="activeUsersChart.config.options"
          [data]="activeUsersChart.config.data"
        ></canvas>
      </div>
    </div>
    <div class="chart" *ngIf="followersChart">
      <h3>
        {{ 'project.detail.statistics.new.stats.followers_gained' | translate }}
        <span
          (click)="openModal('followers_gained')"
          inlineSVG="/assets/v2/img/icons/info.svg"
        ></span>
      </h3>
      <div class="chart__inner">
        <canvas
          baseChart
          [type]="followersChart.config.type"
          [options]="followersChart.config.options"
          [data]="followersChart.config.data"
        ></canvas>
      </div>
    </div>
  </section>

  <section class="statistics">
    <h2>{{ 'project.detail.statistics.new.reach' | translate }}</h2>
    <ng-container
      *ngTemplateOutlet="
        stat;
        context: {
          title: 'notifications',
          number: 1900,
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        stat;
        context: {
          title: 'views',
          number: 1250,
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        stat;
        context: {
          title: 'opened',
          number: 725,
        }
      "
    ></ng-container>
  </section>

  <section class="statistics">
    <div class="chart" *ngIf="reactionsChart">
      <h3>
        {{ 'project.detail.statistics.new.stats.reactions' | translate }}
        <span
          (click)="openModal('reactions')"
          inlineSVG="/assets/v2/img/icons/info.svg"
        ></span>
      </h3>
      <div class="chart__inner">
        <canvas
          baseChart
          [type]="reactionsChart.config.type"
          [options]="reactionsChart.config.options"
          [data]="reactionsChart.config.data"
        ></canvas>
        <div class="legend">
          <div *ngFor="let label of reactionLabels; let i = index">
            <span>
              <span
                [inlineSVG]="'/assets/v2/img/icons/' + label + '.svg'"
              ></span>
              <span>
                {{ 'project.detail.statistics.new.label.' + label | translate }}
              </span>
            </span>
            <span>{{ reactionsChart.config.data.datasets[0].data[i] }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="updates__list" *ngIf="update">
      <h3>
        {{ 'project.detail.statistics.new.update.title' | translate }}
      </h3>
      <div class="prevent-click" (click)="handleUpdateClick($event)"></div>
      <app-default-updates-tile
        [isCustomer]="true"
        [update]="update"
      ></app-default-updates-tile>
    </div>
  </section>

  <section class="charts">
    <h2>{{ 'project.detail.statistics.new.experience' | translate }}</h2>
    <div class="chart" *ngIf="engagementChart">
      <h3>
        {{ 'project.detail.statistics.new.stats.engagement' | translate }}
        <span
          (click)="openModal('engagement')"
          inlineSVG="/assets/v2/img/icons/info.svg"
        ></span>
      </h3>
      <div class="chart__inner">
        <canvas
          baseChart
          [type]="engagementChart.config.type"
          [options]="engagementChart.config.options"
          [data]="engagementChart.config.data"
        ></canvas>
      </div>
    </div>
    <div class="chart" *ngIf="ratingChart">
      <h3>
        {{ 'project.detail.statistics.new.stats.rating' | translate }}
        <span
          (click)="openModal('rating')"
          inlineSVG="/assets/v2/img/icons/info.svg"
        ></span>
      </h3>
      <div class="chart__inner">
        <canvas
          baseChart
          [type]="ratingChart.config.type"
          [options]="ratingChart.config.options"
          [data]="ratingChart.config.data"
        ></canvas>
      </div>
    </div>
    <div class="feedback-link">
      <button
        [routerLink]="['..', 'satisfaction-monitor']"
        class="btn btn-primary"
      >
        {{ 'project.detail.statistics.new.feedback.go' | translate }}
      </button>
    </div>

    <div class="alert tip">
      <span inlineSVG="/assets/v2/img/icons/info.svg"></span>
      <p>
        <span>{{
          'project.detail.statistics.new.tips.prefix' | translate
        }}</span>
        <span>{{
          'project.detail.statistics.new.tips.tip' + tip | translate
        }}</span>
      </p>
    </div>
  </section>

  <section class="statistics">
    <h2>{{ 'project.detail.statistics.new.activity' | translate }}</h2>
    <div class="stat stat__map">
      <h3>
        {{ 'project.detail.statistics.new.stats.map' | translate }}
        <span
          (click)="openModal('map')"
          inlineSVG="/assets/v2/img/icons/info.svg"
        ></span>
      </h3>
      <mgl-map
        #map
        (mapLoad)="mapLoaded($event)"
        [style]="'mapbox://styles/mapbox/streets-v11'"
      >
        <mgl-control
          position="bottom-right"
          [showCompass]="false"
          mglNavigation
        ></mgl-control>
      </mgl-map>
      <div class="legend" *ngIf="interactionsChart">
        <div *ngFor="let filter of mapFilter; let i = index">
          <div class="checkbox">
            <input
              (change)="updateMapFilter($event, i)"
              [checked]="filter.isChecked"
              type="checkbox"
              id="checkbox{{ i }}"
            />
            <label for="checkbox{{ i }}">
              {{
                'project.detail.statistics.new.label.' + filter.label
                  | translate
              }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="chart chart__interactions" *ngIf="interactionsChart">
      <h3>
        {{ 'project.detail.statistics.new.stats.interactions' | translate }}
        <span
          (click)="openModal('interactions')"
          inlineSVG="/assets/v2/img/icons/info.svg"
        ></span>
      </h3>
      <div class="chart__inner">
        <canvas
          baseChart
          [type]="interactionsChart.config.type"
          [options]="interactionsChart.config.options"
          [data]="interactionsChart.config.data"
        ></canvas>
      </div>
    </div>
  </section>

  <section class="conversations">
    <h2>{{ 'project.detail.statistics.new.conversations' | translate }}</h2>
    <div class="conversations__select">
      <select type="select">
        <option>
          {{
            'project.detail.statistics.new.conversations.target.select'
              | translate
          }}
        </option>
        <option *ngFor="let i of [1, 2, 3, 4, 5, 6]">
          {{
            'project.detail.statistics.new.conversations.target.group'
              | translate
          }}
          {{ i }}
        </option>
      </select>
      <select type="select">
        <option>
          {{
            'project.detail.statistics.new.conversations.type.select'
              | translate
          }}
        </option>
        <option>
          {{ 'conversation.type.question' | translate }}
        </option>
        <option>
          {{ 'conversation.type.complaint' | translate }}
        </option>
        <option>
          {{ 'conversation.type.compliment' | translate }}
        </option>
        <option>
          {{ 'conversation.type.information' | translate }}
        </option>
      </select>
    </div>

    <div class="conversations__group">
      <div class="conversations__group__item">
        <span inlineSVG="/assets/v2/img/icons/message.svg"></span>
        <span>
          <span>
            <span>35</span>
          </span>
          <span>{{
            'project.detail.statistics.new.conversations' | translate
          }}</span>
        </span>
        <span class="positive">{{
          'project.detail.statistics.new.conversations.average_more'
            | translate: { value: '1' }
        }}</span>
      </div>

      <div class="conversations__group__item">
        <span inlineSVG="/assets/v2/img/icons/emoji.svg"></span>
        <span>
          <span>
            <span>4.3</span>
            <span>/5.0</span>
          </span>
          <span>{{
            'project.detail.statistics.new.conversations.rating' | translate
          }}</span>
        </span>
        <span class="positive">{{
          'project.detail.statistics.new.conversations.average_higher'
            | translate: { value: '0.3' }
        }}</span>
      </div>

      <div class="conversations__group__item">
        <span inlineSVG="/assets/v2/img/icons/chat.svg"></span>
        <span>
          <span>
            <span>32</span>
            <span>min</span>
          </span>
          <span>{{
            'project.detail.statistics.new.conversations.response_time'
              | translate
          }}</span>
        </span>
        <span class="positive">{{
          'project.detail.statistics.new.conversations.average_faster'
            | translate: { value: '1 hour and 3 minutes' }
        }}</span>
      </div>

      <div class="conversations__group__item">
        <span inlineSVG="/assets/v2/img/icons/check.svg"></span>
        <span>
          <span>
            <span>12</span>
            <span>d</span>
          </span>
          <span>{{
            'project.detail.statistics.new.conversations.handling_time'
              | translate
          }}</span>
        </span>
        <span class="negative">{{
          'project.detail.statistics.new.conversations.average_more'
            | translate: { value: '1 day' }
        }}</span>
      </div>
    </div>
  </section>

  <ng-template #stat let-title="title" let-number="number">
    <div class="stat">
      <h3>
        {{ 'project.detail.statistics.new.stats.' + title | translate }}
        <span
          (click)="openModal(title)"
          inlineSVG="/assets/v2/img/icons/info.svg"
        ></span>
      </h3>
      <span>{{ number | number: '1.0-0' }}</span>
    </div>
  </ng-template>

  <app-modal #modal [title]="modalTitle">
    <div class="modal-description" [innerHTML]="modalDesc"></div>
  </app-modal>
</ng-container>
