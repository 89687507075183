import { Component, OnChanges, input } from '@angular/core';
import { Customer } from 'src/app/interfaces/customer';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { UserDataService } from 'src/app/services/user-data.service';
import { ManageUsersService } from './manage-users.service';
import { Project } from 'src/app/interfaces/project';
import { LogEntry } from 'src/app/interfaces/log-entry';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgFor, DatePipe, KeyValuePipe } from '@angular/common';
import { VersionDirective } from 'src/app/directives/version.directive';
import { UserRolesType } from 'src/app/enums/user-roles-type';
import { LoaderComponent } from '../loader.component';
import { ProjectUserPermissions } from 'src/app/interfaces/project-user-permissions';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
  selector: 'app-list-users',
  templateUrl: 'list-users.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    InlineSVGModule,
    DatePipe,
    KeyValuePipe,
    VersionDirective,
    TranslateModule,
    LoaderComponent,
    AvatarComponent,
  ],
})
export class ListUsersComponent implements OnChanges {
  readonly projectUsers = input<ProjectUser[]>(undefined);
  readonly customer = input<Customer>(undefined);
  readonly loading = input<boolean>(undefined);
  readonly project = input<Project>(undefined);
  readonly editAdmins = input<boolean>(true);

  public projectUser: ProjectUser;

  constructor(
    private userDataService: UserDataService,
    private manageUsersService: ManageUsersService,
  ) {
    this.retrieveUser();
  }

  ngOnChanges(): void {
    const projectUsers = this.projectUsers();
    if (projectUsers) {
      const index = projectUsers.findIndex(
        (user) => this.projectUser.id === user.id,
      );
      if (index > -1) {
        const [item] = projectUsers.splice(index, 1);
        projectUsers.unshift(item);
      }

      if (this.project()) {
        projectUsers.forEach(
          (pu: ProjectUser) =>
            (pu.lastLogEntry = pu.lastLogEntryPerProject.find(
              (logEntry: LogEntry) =>
                logEntry.project?.id === this.project().id,
            )),
        );
      }
    }
  }

  async retrieveUser() {
    this.projectUser = await this.userDataService.retrieveProjectUser();
  }

  public getNumberOfProjects(
    permissionName: UserRolesType,
    permissions: ProjectUserPermissions[],
  ) {
    if (
      permissionName === UserRolesType.PROJECT_ADMIN &&
      permissions.length === 1
    ) {
      if (permissions[0].customer) {
        return 0;
      }
    }

    return permissions.length;
  }

  public triggerDelete(projectUser: ProjectUser) {
    this.manageUsersService.triggerDeleteEvent(projectUser);
  }
  public hasPermission(user: ProjectUser) {
    let userWithPermission = this.projectUsers().find(
      (user) => user.id === this.projectUser.id,
    );
    if (undefined === userWithPermission) {
      userWithPermission = this.projectUser;
    }

    const permission =
      this.manageUsersService.determinePermission(userWithPermission);

    if (permission === UserRolesType.ACCOUNT_ADMIN) {
      return (
        this.editAdmins() ||
        this.manageUsersService.determinePermission(user) !==
          UserRolesType.ACCOUNT_ADMIN
      );
    }

    if (permission === UserRolesType.PROJECT_ADMIN) {
      return ![
        UserRolesType.PROJECT_ADMIN,
        UserRolesType.ACCOUNT_ADMIN,
      ].includes(this.manageUsersService.determinePermission(user));
    }

    return true;
  }

  public triggerEdit(projectUser: ProjectUser) {
    this.manageUsersService.triggerEditEvent(projectUser);
  }

  public isEmpty(): boolean {
    return !this.projectUsers()?.length;
  }

  public getIconForPermission(user: ProjectUser): {
    icon: string;
    type: string;
  } {
    const permission = this.manageUsersService.determinePermission(user);
    return { icon: `/assets/img/icons/${permission}.svg`, type: permission };
  }
}
