<div
  [ngClass]="{ container: getIsCustomer() }"
  class="tickets"
  *ngIf="ticketTab !== 'settings'"
>
  <div class="bouwapp-header">
    <div class="bouwapp-header__text">
      <h1 *appVersion="1">{{ 'tickets.all.title' | translate }}</h1>
      <h1 *appVersion="2">{{ 'project.detail.tickets.title' | translate }}</h1>
      <p *ngIf="project && onProjectPage && version === 1">
        {{ 'ticket.incoming.label' | translate }}
        <br />
        <strong>{{ project.projectMail }}</strong>
      </p>
    </div>
    <div class="bouwapp-header__buttons" *appVersion="1">
      <button
        class="primary"
        *ngIf="onProjectPage"
        (click)="createTicket = !createTicket"
        appAccess
        role="manager"
        [project]="project"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'ticket.create.title' | translate }}</span>
      </button>
    </div>
    <div class="bouwapp-header__buttons" *appVersion="2">
      <button
        *ngIf="!onProjectPage"
        class="tertiary"
        (click)="downloadExport()"
      >
        <span>{{ 'project.detail.tickets.export' | translate }}</span>
      </button>
      <button
        class="secondary"
        *ngIf="project && onProjectPage"
        appAccess
        (click)="modal.open()"
        role="manager"
        [project]="project"
      >
        <span class="icon" [inlineSVG]="'/assets/icons/add--white.svg'"></span>
        <span>{{ 'project.detail.tickets.new' | translate }}</span>
      </button>
    </div>
    <div class="bouwapp-header__content" *appVersion="2">
      <p
        *ngIf="project && onProjectPage && project.projectMail"
        [innerHTML]="
          'ticket.incoming.label.v2' | translate: { email: project.projectMail }
        "
      ></p>
      <div
        class="app__user__self"
        *ngIf="project && onProjectPage"
        (click)="navigateToContact()"
      >
        <div class="app__user__image">
          <ng-container
            *ngIf="
              project?.chatPlaceholderAvatarThumbnails?.small;
              else noAvatar
            "
          >
            <img
              [src]="project.chatPlaceholderAvatarThumbnails?.small"
              width="40px"
              height="40px"
            />
          </ng-container>
          <ng-template #noAvatar>
            <span
              *ngIf="
                project?.chatPlaceholderName &&
                project?.chatPlaceholderName.length > 0
              "
            >
              {{ project?.chatPlaceholderName[0] }}
            </span>
          </ng-template>
        </div>
        <div class="app__user__text">
          <span>
            <span>
              {{ project?.chatPlaceholderName }}
            </span>
            <span class="app__user__text__small">{{
              project.projectMail
            }}</span>
          </span>
        </div>
        <div class="app__user__toggle">
          <span inlineSVG="/assets/v2/img/icons/pencil.svg"></span>
        </div>
      </div>
    </div>
  </div>
  <app-data-filter-v2
    (filterChanged)="updateFilter($event)"
    [customFilters]="transformToFilters()"
    [searchLabel]="'tickets.all.search'"
    [sortItems]="version === 2 ? sortItems : []"
    [sortLabel]="'project.list.sort'"
  >
    <div class="tickets__content">
      <div
        class="tickets__wrapper"
        infiniteScroll
        (scrolled)="loadTickets()"
        [infiniteScrollContainer]="'div.app__content'"
        [infiniteScrollDisabled]="tickets?.length == totalTickets"
        [fromRoot]="true"
      >
        <p *ngIf="tickets && tickets.length">
          {{
            'ticket_list.result.title' | translate: { tickets: totalTickets }
          }}
        </p>
        <div class="tickets__list">
          <app-ticket-list
            *ngIf="tickets"
            [createTicket]="createTicket"
            [tickets]="tickets"
            [loading]="loading"
            [ticketsAllCount]="totalTickets"
            [withProject]="!getIsCustomer()"
          ></app-ticket-list>
          <app-loader *ngIf="loading"></app-loader>
        </div>
      </div>
    </div>
  </app-data-filter-v2>
</div>

<app-modal
  class="ticket-modal"
  [title]="'project.detail.tickets.new' | translate"
  #modal
>
  <div slot="header">
    {{ 'project.detail.tickets.modal.header' | translate }}
  </div>
  <form appFormChangeDetector [formGroup]="ticketForm" (submit)="saveTicket()">
    <app-form-group [form]="ticketForm" errorPath="name">
      <label for="subject">{{ 'ticket_list.table.name' | translate }}</label>
      <input
        type="text"
        class="input input--m"
        name="name"
        id="name"
        formControlName="name"
      />
    </app-form-group>
    <app-form-group [form]="ticketForm" errorPath="subject">
      <label for="subject">{{ 'ticket_list.table.subject' | translate }}</label>
      <input
        type="text"
        name="subject"
        id="subject"
        class="input input--m"
        formControlName="subject"
      />
    </app-form-group>
    <app-form-group [form]="ticketForm" errorPath="content">
      <label for="content">{{
        'projects.detail.announcement.edit.message' | translate
      }}</label>
      <textarea
        name="content"
        id="content"
        class="textarea"
        formControlName="content"
      ></textarea>
    </app-form-group>
    <button
      class="button button--m button--flat bg--blue"
      type="submit"
      [appLoading]="loading"
    >
      {{ 'project.detail.tickets.modal.submit' | translate }}
    </button>
  </form>
</app-modal>
<app-default-projects-detail-contact
  *ngIf="ticketTab === 'settings'"
></app-default-projects-detail-contact>
