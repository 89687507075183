import { Component, effect } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from '../../../services/error.service';
import { CustomerService } from '../../../services/customer.service';
import { Customer } from '../../../interfaces/customer';
import { ProjectUser } from 'src/app/interfaces/project-user';
import { ProjectUserService } from 'src/app/services/project-user.service';
import { UserService } from 'src/app/services/user.service';
import { Project } from 'src/app/interfaces/project';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorMessageComponent } from '../../../components/error-message.component';
import { SuccessMessageComponent } from '../../../components/success-message.component';
import { ListUsersComponent } from '../../../components/manage-users/list-users.component';
import { ManageUsersComponent } from '../../../components/manage-users/manage-users.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ThemeService } from 'src/app/services/theme.service';
import { Sort } from 'src/app/interfaces/sort';
import { VersionDirective } from 'src/app/directives/version.directive';
import { UserRolesType } from 'src/app/enums/user-roles-type';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { LoaderComponent } from 'src/app/components/loader.component';
import { CustomFilter } from 'src/app/interfaces/custom-filter';
import { CustomFilterItem } from 'src/app/interfaces/custom-filter-item';
import { CustomFilterType } from 'src/app/enums/custom-filter-type';
import { DataFilterV2Component } from 'src/app/components/data-filter-v2.component';

@Component({
  selector: 'app-user-admin',
  templateUrl: 'user-admin.component.html',
  standalone: true,
  imports: [
    ManageUsersComponent,
    ListUsersComponent,
    SuccessMessageComponent,
    ErrorMessageComponent,
    TranslateModule,
    NgIf,
    VersionDirective,
    DataFilterV2Component,
    NgFor,
    NgClass,
    LoaderComponent,
  ],
})
export class UserAdminComponent {
  public formSaved = false;
  public customer: Customer;
  public projects: Project[];

  public inviteSaved: boolean = false;
  public cannotSave: boolean = false;
  public editSaved: boolean = false;
  public formLoading: boolean = false;
  public deleted: boolean = false;
  public loading: boolean = false;
  public project: Project;

  public projectUsers: ProjectUser[] = [];
  public editingMapping: ProjectUser;

  public showCreateProjectUser = false;
  public projectUser: ProjectUser;
  public version: number;
  public roleCounts: any;
  private search = null;
  private roles = null;
  public sortItems: Sort[] = [
    {
      field: 'projectRole',
      direction: 'desc',
      label: 'project.detail.settings.users.sort.direction.role.descending',
    },
    {
      field: 'projectRole',
      direction: 'asc',
      label: 'project.detail.settings.users.sort.direction.role.ascending',
    },
    {
      field: 'lastName',
      direction: 'desc',
      label: 'project.detail.settings.users.sort.direction.name.descending',
    },
    {
      field: 'lastName',
      direction: 'asc',
      label: 'project.detail.settings.users.sort.direction.name.ascending',
    },
    {
      field: 'lastEvent',
      direction: 'desc',
      label: 'project.detail.settings.users.sort.direction.event.descending',
    },
    {
      field: 'lastEvent',
      direction: 'asc',
      label: 'project.detail.settings.users.sort.direction.event.ascending',
    },
  ];
  private sort: Sort = this.sortItems[0];

  constructor(
    private errorService: ErrorService,
    private projectUserService: ProjectUserService,
    private userService: UserService,
    private customerService: CustomerService,
    private projectDataBusService: ProjectDataBusService,
    private activatedRoute: ActivatedRoute,
    private themeService: ThemeService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] != null) {
        this.loadCustomer(params['id']);
      }
    });

    this.activatedRoute.parent?.params.subscribe((params) => {
      if (!params.slug) {
        this.project = null;
      } else {
        this.projectDataBusService.projectObservable.subscribe((project) => {
          this.project = project;
          this.loadUsers();
        });
      }
    });
  }

  async loadCustomer(id: number): Promise<void> {
    this.loading = true;
    this.customer = await this.customerService.fetch(id);

    this.loadUsers();
  }

  async ngOnInit() {
    this.version = await this.themeService.getVersion();
  }

  updateFilter(data: any) {
    this.sort = data.sort;
    this.search = data.search;
    this.roles =
      data.customFilters
        .find(
          (f) =>
            f.title === 'project.list.filter.status.title' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => filter.key) || [];

    this.loadUsers();
  }

  private async loadUsers(): Promise<void> {
    try {
      this.loading = true;

      if (this.project) {
        await this.projectUserService
          .fetchListForProject(this.project, this.sort, this.search, this.roles)
          .then((result) => {
            this.projectUsers = result;
          });
      } else {
        if (!this.customer) {
          return;
        }
        await this.projectUserService
          .fetchListForCustomer(
            this.customer,
            this.sort,
            this.search,
            this.roles,
          )
          .then((result) => {
            if (this.version === 1) {
              this.projectUsers = result.filter(
                (item) =>
                  item.sortedPermissions.account_admin.length > 0 ||
                  item.sortedPermissions.project_admin.length > 0,
              );
            } else {
              this.projectUsers = result;
            }
          });
      }
    } catch (error) {
      this.errorService.logError(error);
    } finally {
      this.loading = false;
    }
  }

  public async create(data: {
    projectUser: ProjectUser;
    formData: any;
  }): Promise<void> {
    this.cannotSave = false;
    this.inviteSaved = false;

    try {
      await this.userService.inviteMultiple(
        data.projectUser,
        data.formData,
        this.project ? 'single' : 'multiple',
      );
    } catch (e) {
      this.cannotSave = true;
    }

    if (!this.cannotSave) {
      this.inviteSaved = true;
    }

    this.loadUsers();
  }

  public async update(data: { projectUser: ProjectUser; formData: any }) {
    this.cannotSave = false;
    this.editSaved = false;

    try {
      await this.userService.updateInviteMultiple(
        data.projectUser,
        data.formData,
        this.project ? 'single' : 'multiple',
      );
    } catch (error) {
      console.error(error);
      this.cannotSave = true;
    } finally {
      if (!this.cannotSave) {
        this.editSaved = true;
      }

      this.formLoading = false;
    }
    this.loadUsers();
  }

  public async getRoleCount() {
    this.roleCounts = [];
  }

  get allRoles() {
    return Object.values(UserRolesType);
  }

  transformToFilters(): CustomFilter[] {
    const filters: CustomFilter[] = [];

    if (this.allRoles.length > 0) {
      const roleFilter: CustomFilterItem[] = this.allRoles.map(
        (role, index) => ({
          label: `project.list.filter.role.${role}`,
          value: true,
          key: role,
          count: this.roleCounts ? this.roleCounts[role] : undefined,
        }),
      );

      filters.push({
        title: 'project.list.filter.status.title',
        filters: roleFilter,
        type: CustomFilterType.CHECKBOX,
      });
    }

    return filters;
  }

  public async delete(projectUser: ProjectUser) {
    this.deleted = false;
    if (this.project) {
      for (const role in projectUser.sortedPermissions) {
        if (Object.values(UserRolesType).includes(role as UserRolesType)) {
          const permissions =
            projectUser.sortedPermissions[role as UserRolesType];

          if (permissions) {
            const deletePromises = permissions.map((permission) =>
              this.userService.deletePermission(permission.id),
            );
            await Promise.all(deletePromises);
          }
        }
      }
    } else {
      await this.userService.deletePermissions(projectUser, this.customer);
    }
    this.deleted = true;
    this.loadUsers();
  }

  public changeCustomer(customer: Customer) {
    this.loadCustomer(customer.id);
    this.loadUsers();
  }
}
