import { Component, Input, OnInit } from '@angular/core';
import { SatisfactionDistribution } from '../interfaces/satisfaction-distribution';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgFor } from '@angular/common';
import { ThemeService } from '../services/theme.service';

@Component({
  selector: 'app-satisfaction-distribution',
  templateUrl: 'satisfaction-distribution.component.html',
  standalone: true,
  imports: [NgFor, InlineSVGModule],
})
export class SatisfactionDistributionComponent implements OnInit {
  @Input() public set data(data: SatisfactionDistribution) {
    if (!data) {
      return;
    }

    Object.keys(data).forEach(
      (score) => (this.internalData[score] = data[score]),
    );
  }

  private version: number = 1;

  constructor(private themeService: ThemeService) {}

  async ngOnInit() {
    this.version = await this.themeService.getVersion();
  }

  public internalData: SatisfactionDistribution = {
    '1': 0,
    '2': 0,
    '3': 0,
    '4': 0,
    '5': 0,
  };

  public getWidth(score: number) {
    const total = this.getTotal();

    if (total === 0 || this.version === 2) {
      return '20%';
    }

    return (this.internalData[score] / total) * 100 + '%';
  }

  public getTotal(): number {
    let total = 0;

    for (let i = 1; i <= 5; i++) {
      const score = this.internalData[i];

      total += score;
    }

    return total;
  }
}
