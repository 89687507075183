<span>
  <span class="label label-primary" *ngIf="ticket().status === 'OPEN'">{{
    'ticket.status.open' | translate
  }}</span>
  <span class="label label-info" *ngIf="ticket().status === 'PENDING'">{{
    'ticket.status.pending' | translate
  }}</span>
  <span class="label label-success" *ngIf="ticket().status === 'CLOSED'">{{
    'ticket.status.closed' | translate
  }}</span>
</span>
<span>
  <span class="date">{{
    ticket().lastInteractionDate | date: 'mediumDate'
  }}</span>
  <span class="time">{{
    ticket().lastInteractionDate | date: 'shortTime'
  }}</span>
</span>
<span
  [attr.aria-label]="'ticket_list.table.project' | translate"
  *ngIf="!withProject()"
>
  {{ ticket().project.name }}
</span>
<span [attr.aria-label]="'ticket_list.table.name' | translate">
  {{ ticket().name }}
</span>
<span [attr.aria-label]="'ticket_list.table.subject' | translate">
  {{ ticket().subject }}
</span>
<span [attr.aria-label]="'ticket_list.table.rating' | translate">
  <div
    *appVersion="1"
    [inlineSVG]="'/assets/img/icons/smiley' + ticket().feedback + '.svg'"
    class="inline-svg__item ticket-feedback"
  ></div>
  <div *appVersion="2">
    <span *ngIf="!ticket().feedback">-&nbsp;</span>{{ ticket().feedback }} / 5
    <a>{{ 'ticket.edit' | translate }}</a>
  </div>
</span>
