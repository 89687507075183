import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { Ticket } from '../../interfaces/ticket';
import { TicketService } from '../../services/ticket.service';
import { ThemeService } from 'src/app/services/theme.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ConversationComponent } from '../../components/conversation.component';
import { ModalComponent } from '../../components/modal.component';
import { FooterComponent } from '../../components/footer.component';
import { RouterOutlet } from '@angular/router';
import { BreadcrumbsComponent } from '../../components/breadcrumbs.component';
import { HeaderComponent } from '../../components/header.component';
import { NgIf, NgFor } from '@angular/common';
import { VersionDirective } from 'src/app/directives/version.directive';
import { ProductFruitsService } from 'src/app/services/product-fruits.service';

@Component({
  selector: '[app-default]',
  templateUrl: 'default.component.html',
  standalone: true,
  imports: [
    NgxSpinnerModule,
    NgIf,
    VersionDirective,
    HeaderComponent,
    BreadcrumbsComponent,
    RouterOutlet,
    FooterComponent,
    ModalComponent,
    NgFor,
    ConversationComponent,
  ],
})
export class DefaultComponent implements OnInit {
  @ViewChildren('targetComp', { read: ElementRef })
  conversationComponents: QueryList<ElementRef>;
  @ViewChild('imageModal')
  public imageModal: ModalComponent;

  private static SIZE_PER_CONVERSATION = 400;
  public openTickets: Ticket[] = [];
  private maximumAmountOfTickets: number;

  fullscreen: boolean = false;
  version: number;
  ready: boolean = false;
  themeSubscription: Subscription;

  constructor(
    private ticketService: TicketService,
    private projectDataBusService: ProjectDataBusService,
    private productFruitsService: ProductFruitsService,
    private themeService: ThemeService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
  ) {
    this.calculateMaximumAmountOfTickets();
    this.spinner.show();
    this.themeSubscription = this.themeService.cssLoadedSubject.subscribe(
      (version) => {
        if (version) {
          this.version = version;
          this.spinner.hide();
          this.ready = true;
        }
      },
    );
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.imageModal) {
      if (this.imageModal.wasClickedInside(event)) {
        return;
      } else {
        if (this.imageModal.isOpen) {
          this.imageModal.close();
          return;
        }
      }
    }

    const selection = window.getSelection();
    if (selection && !selection.isCollapsed) {
      return;
    }

    let clickedInsideAnyComponent = false;

    this.conversationComponents.forEach((targetComp) => {
      const clickedInside = targetComp.nativeElement.contains(event.target);
      if (clickedInside) {
        clickedInsideAnyComponent = true;
      }
    });

    if (!clickedInsideAnyComponent) {
      this.openTickets.map((ticket) => {
        this.ticketService.closeTicket(ticket);
      });
    }
  }

  async ngOnInit() {
    this.productFruitsService.init();

    this.openTickets = Array.from(await this.ticketService.getOpenTickets());

    this.ticketService.ticketOpened.subscribe((ticket: Ticket) => {
      if (this.openTickets.length + 1 > this.maximumAmountOfTickets) {
        this.ticketService.closeTicket(this.openTickets[0]);
      }

      this.openTickets.push(ticket);
    });

    this.ticketService.ticketClosed.subscribe((ticket: Ticket) => {
      this.openTickets = this.openTickets.filter((item: Ticket) => {
        return item.id !== ticket.id; // object comparison won't work because of serialized objects
      });
    });

    this.projectDataBusService.isConceptObservable.subscribe((value) => {
      this.fullscreen = this.version === 1 ? value : false;
      this.cdr.detectChanges();
    });
  }

  /**
   * Method to calculate how many conversations can be opened at once
   */
  private calculateMaximumAmountOfTickets() {
    this.maximumAmountOfTickets = Math.floor(
      window.screen.width / DefaultComponent.SIZE_PER_CONVERSATION,
    );
  }
}
