<app-success-message [hidden]="!inviteSaved" [onScreen]="true">
  {{ 'project.detail.settings.users.invited' | translate }}
</app-success-message>

<app-success-message [hidden]="!editSaved" [onScreen]="true">
  {{ 'project.detail.settings.users.updated' | translate }}
</app-success-message>

<app-success-message [hidden]="!deleted" [onScreen]="true">
  {{ 'project.detail.settings.users.delete' | translate }}
</app-success-message>

<app-error-message [hidden]="!cannotSave" [onScreen]="true">
  {{ 'project.detail.settings.users.exists' | translate }}
</app-error-message>

<section
  class="projects detail projects-settings"
  [ngClass]="{ container: !project }"
>
  <app-manage-users
    [loading]="loading"
    [projectUsers]="projectUsers"
    (deleteUser)="delete($event)"
    (editUser)="update($event)"
    (createUser)="create($event)"
    [customerInput]="customer"
    [project]="project"
    (selectedCustomer)="changeCustomer($event)"
  >
  </app-manage-users>
  <ng-container *appVersion="1">
    <app-list-users
      [loading]="loading"
      [projectUsers]="projectUsers"
      [customer]="customer"
    ></app-list-users>
  </ng-container>
  <app-data-filter-v2
    *appVersion="2"
    (filterChanged)="updateFilter($event)"
    [sortItems]="sortItems"
    [customFilters]="transformToFilters()"
    [searchLabel]="'project.detail.settings.users.search'"
    [sortLabel]="'project.list.sort'"
  >
    <div class="projects-settings__users__wrapper">
      <p [hidden]="loading || !projectUsers?.length">
        {{
          'project.detail.settings.users.table.result.title'
            | translate: { total: projectUsers.length }
        }}
      </p>
      <div class="users__list">
        <app-list-users
          [loading]="loading"
          [projectUsers]="projectUsers"
          [customer]="customer ?? project?.customer"
          [project]="project"
          [editAdmins]="project ? false : true"
        ></app-list-users>
      </div>
      <app-loader *ngIf="loading"></app-loader>
    </div>
  </app-data-filter-v2>
</section>
