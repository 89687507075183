import { Injectable } from '@angular/core';
import { ProjectUser } from '../interfaces/project-user';
import { Project } from '../interfaces/project';
import { Update } from '../interfaces/update';
import { ProjectService } from './project.service';
import { UpdateAttachment } from '../interfaces/update-attachment';
import { CollectionHelper } from '../shared/collection-helper';
import { PollChoice } from '../interfaces/poll-choice';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CollectionResponse } from '../interfaces/collection-response';
import { environment } from '../../environments/environment';
import { UpdateCategory } from '../interfaces/update-category';
import { ProjectUserService } from './project-user.service';
import { Customer } from '../interfaces/customer';
import { CustomerService } from './customer.service';
import { firstValueFrom, Subscription } from 'rxjs';

@Injectable()
export class UpdateService {
  static base = '/updates';
  static categoryBase = '/update-categories/description';
  static attachmentBase = '/update-attachments';
  static pollChoiceBase = '/poll-choices';
  private listRequest: Subscription = null;
  private statusRequest: Subscription = null;

  constructor(private http: HttpClient) {}

  public listByProject(
    project: Project,
    page: number = 1,
    perPage: number = 10,
    sort: string = 'desc',
  ): Promise<CollectionResponse<Update>> {
    let params: HttpParams = new HttpParams();
    params = params.set('order[publicationDate]', sort);
    params = params.set('perPage', String(perPage));
    params = params.set('page', String(page));

    return this.http
      .get<
        CollectionResponse<Update>
      >(environment.apiUrl + ProjectService.base + '/' + project.slug + UpdateService.base, { params })
      .toPromise();
  }

  public listByProjectV2(
    project: Project,
    page: number = 1,
    perPage: number = 10,
    sort: string = 'desc',
    search?: string,
    statuses?: string[],
    startDate?: Date,
    endDate?: Date,
    cancelPrevious: boolean = true,
  ): Promise<CollectionResponse<Update>> {
    return new Promise((resolve, reject) => {
      if (cancelPrevious) {
        this.listRequest?.unsubscribe();
      }
      let params: HttpParams = new HttpParams();
      params = params.set('order[publicationDate]', sort);
      params = params.set('perPage', String(perPage));
      params = params.set('page', String(page));

      let urlEnd = '?';

      if (search != null && search !== '') {
        params = params.set('search', search);
      }

      if (startDate != null)
        params = params.set('startDate', startDate.toString());
      if (endDate != null) params = params.set('endDate', endDate.toString());

      if (statuses != null && statuses.length > 0) {
        for (const status of statuses) {
          urlEnd += `status[]=${status}&`;
        }
      }

      this.listRequest = this.http
        .get<
          CollectionResponse<Update>
        >(environment.apiUrlV3 + ProjectService.base + '/' + project.slug + UpdateService.base + urlEnd.slice(0, -1), { params })
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  public listUpdates(
    projectUser: ProjectUser,
    page: number = 1,
    perPage: number = 10,
    sort: string = 'desc',
  ) {
    let params: HttpParams = new HttpParams();
    params = params.set('order[publicationDate]', sort);
    params = params.set('perPage', String(perPage));
    params = params.set('page', String(page));

    return this.http
      .get<
        CollectionResponse<Update>
      >(environment.apiUrl + ProjectUserService.base + '/' + projectUser.id + UpdateService.base, { params })
      .toPromise();
  }

  public listUpdatesV2(
    projectUser: ProjectUser,
    page: number = 1,
    perPage: number = 10,
    sort: string = 'desc',
    search?: string,
    statuses?: string[],
    startDate?: Date,
    endDate?: Date,
    customerIds?: number[],
    projectIds?: number[],
    cancelPrevious: boolean = true,
  ): Promise<CollectionResponse<Update>> {
    return new Promise((resolve, reject) => {
      if (cancelPrevious) {
        this.listRequest?.unsubscribe();
      }

      let params: HttpParams = new HttpParams();
      params = params.set('order[publicationDate]', sort);
      params = params.set('perPage', String(perPage));
      params = params.set('page', String(page));

      let urlEnd = '?';

      if (search != null && search !== '') {
        params = params.set('search', search);
      }

      if (startDate != null)
        params = params.set('startDate', startDate.toString());
      if (endDate != null) params = params.set('endDate', endDate.toString());

      if (statuses != null && statuses.length > 0) {
        for (const status of statuses) {
          urlEnd += `status[]=${status}&`;
        }
      }

      if (customerIds != null && customerIds.length > 0) {
        for (const customerId of customerIds) {
          urlEnd += `customer[]=${customerId}&`;
        }
      }

      if (projectIds != null && projectIds.length > 0) {
        for (const projectId of projectIds) {
          urlEnd += `project[]=${projectId}&`;
        }
      }

      this.listRequest = this.http
        .get<
          CollectionResponse<Update>
        >(environment.apiUrlV3 + ProjectUserService.base + '/' + projectUser.id + UpdateService.base + urlEnd.slice(0, -1), { params })
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  public getStatusCount(
    project?: Project,
    search?: string,
    startDate?: Date,
    endDate?: Date,
    customerIds?: number[],
    projectIds?: number[],
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.statusRequest?.unsubscribe();
      let params: HttpParams = new HttpParams();

      let urlEnd = '?';

      if (project && project.id) {
        params = params.set('project', project.id);
      }

      if (search != null && search !== '') {
        params = params.set('search', search);
      }

      if (startDate != null)
        params = params.set('startDate', startDate.toString());
      if (endDate != null) params = params.set('endDate', endDate.toString());

      if (customerIds != null && customerIds.length > 0) {
        for (const customerId of customerIds) {
          urlEnd += `customer[]=${customerId}&`;
        }
      }

      if (projectIds != null && projectIds.length > 0) {
        for (const projectId of projectIds) {
          urlEnd += `project[]=${projectId}&`;
        }
      }

      this.statusRequest = this.http
        .get<
          CollectionResponse<Update>
        >(environment.apiUrlV3 + '/updates-count' + urlEnd.slice(0, -1), { params })
        .subscribe({
          next: (response) => resolve(response),
          error: (error) => reject(error),
        });
    });
  }

  public create(entity: Project | Customer, data: any): Promise<{}> {
    if ((entity as Project).status !== undefined) {
      data.project = '/api' + ProjectService.base + '/' + entity.slug;
    } else {
      data.customer = '/api' + CustomerService.base + '/' + entity.slug;
    }
    const poll = data.poll;

    if (poll != null) {
      delete poll.id;
      delete poll['@id'];
      delete poll['@type'];

      for (const choiceKey of Object.keys(poll.choices)) {
        const choice = poll.choices[choiceKey];

        delete choice.preview;
        delete choice.id;
        delete choice['@id'];
      }
    }

    data.targets = CollectionHelper.addHydraId(data.targets, 'slug', 'targets');
    data.targets = CollectionHelper.mapHydraId(data.targets);

    return this.http
      .post<Update>(
        environment.apiUrl + UpdateService.base + '?noContent',
        data,
      )
      .toPromise();
  }

  /**
   * @param {Update} update
   * @returns {Promise<void>}
   */
  public async remove(update: Update): Promise<void> {
    await this.http
      .delete(environment.apiUrl + UpdateService.base + '/' + update.id)
      .toPromise();

    return;
  }

  /**
   * @param {UpdateAttachment} attachment
   * @returns {Promise<UpdateAttachment>}
   */
  public updateAttachment(
    attachment: UpdateAttachment,
  ): Promise<UpdateAttachment> {
    return this.http
      .put<UpdateAttachment>(
        environment.apiUrl + UpdateService.attachmentBase + '/' + attachment.id,
        attachment,
      )
      .toPromise();
  }

  public updateChoice(choice: PollChoice) {
    return this.http
      .put(
        environment.apiUrl + UpdateService.pollChoiceBase + '/' + choice.id,
        choice,
      )
      .toPromise();
  }

  /**
   * @param {Update} update
   * @returns {Promise<Update>}
   */
  public async update(update: Update): Promise<Update> {
    const poll: any = update.poll;

    if (poll != null) {
      const choices = [];
      for (const choiceKey of Object.keys(poll.choices)) {
        const choice = poll.choices[choiceKey];

        choices.push(choice);
      }
    }

    update.targets = CollectionHelper.addHydraId(
      update.targets,
      'slug',
      'targets',
    );
    update.targets = CollectionHelper.mapHydraId(update.targets);

    return await this.http
      .put<Update>(
        environment.apiUrl + UpdateService.base + '/' + update.id,
        update,
      )
      .toPromise();
  }

  /**
   * @param {number} id
   * @returns {Promise<Update>}
   */
  public fetch(id: number): Promise<Update> {
    return this.http
      .get<Update>(environment.apiUrl + UpdateService.base + '/' + id)
      .toPromise();
  }

  public updateAttachmentSortOrder(id, data): Promise<UpdateAttachment[]> {
    return this.http
      .post(
        environment.apiUrl + UpdateService.attachmentBase + '/' + id + '/sort',
        data,
      )
      .toPromise()
      .then((response) => {
        return response as Promise<UpdateAttachment[]>;
      });
  }

  public listCategories(): Promise<UpdateCategory[]> {
    return this.http
      .get(environment.apiUrlV3 + UpdateService.categoryBase)
      .toPromise()
      .then((response) => {
        return response as Promise<UpdateCategory[]>;
      });
  }

  public getStatistics(id: number): Promise<any> {
    return this.http
      .get<Update>(
        environment.apiUrlV3 + UpdateService.base + '/' + id + '/statistics',
      )
      .toPromise();
  }

  public getInteractions(id: number): Promise<any> {
    return this.http
      .get<Update>(
        environment.apiUrlV3 + UpdateService.base + '/' + id + '/interactions',
      )
      .toPromise();
  }

  public getUserLocations(id: number): Promise<any> {
    return this.http
      .get<Update>(
        environment.apiUrlV3 +
          UpdateService.base +
          '/' +
          id +
          '/users-locations',
      )
      .toPromise();
  }
}
