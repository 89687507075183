<div class="projects-settings__users" *appVersion="1">
  <ul class="projects-settings__users__head">
    <li>
      {{ 'project.detail.settings.users.table.name.role' | translate }}
    </li>
    <li>
      {{ 'project.detail.settings.users.table.email.phone' | translate }}
    </li>
    <li>
      {{ 'project.detail.settings.users.table.external' | translate }}
    </li>
    <li>
      {{ 'project.detail.settings.users.table.external.type' | translate }}
    </li>
    <li>
      {{ 'project.detail.settings.users.table.actions' | translate }}
    </li>
  </ul>
  <app-loader *ngIf="loading()"></app-loader>
  <div class="projects-settings__users__content" [hidden]="loading()">
    <table>
      <tbody>
        <tr *ngFor="let user of projectUsers()">
          <td
            [attr.data-th]="
              'project.detail.settings.users.table.name.role' | translate
            "
          >
            <span class="user-type">
              {{ user.firstName }}
              {{ user.lastName }} <br />
              <span [class]="getIconForPermission(user).type">
                <span [inlineSVG]="getIconForPermission(user).icon"></span>
                <span>{{
                  'project.detail.settings.users.table.type.' +
                    getIconForPermission(user).type | translate
                }}</span>
              </span>
            </span>
          </td>
          <td
            [attr.data-th]="
              'project.detail.settings.users.table.email.phone' | translate
            "
          >
            {{ user.user.email }} <br />
            {{ user.phoneNumber }}
          </td>
          <td
            [attr.data-th]="
              'project.detail.settings.users.table.external' | translate
            "
          >
            <span *ngIf="user.lastLogEntry">{{
              user.lastLogEntry.executedAt | date: 'HH:mm dd-MM-yyyy'
            }}</span>
            <span *ngIf="!user.lastLogEntry">-</span>
          </td>
          <td
            [attr.data-th]="
              'project.detail.settings.users.table.external.type' | translate
            "
          >
            <span *ngIf="user.lastLogEntry"
              >{{ user.lastLogEntry.entityType | translate }}
              {{ user.lastLogEntry.action | translate }}</span
            >
            <span *ngIf="!user.lastLogEntry">-</span>
          </td>
          <td
            [attr.data-th]="
              'project.detail.settings.users.table.actions' | translate
            "
          >
            <ng-container
              *ngIf="user.id !== projectUser.id && hasPermission(user)"
            >
              <div
                [inlineSVG]="'/assets/img/icons/edit-small.svg'"
                (click)="triggerEdit(user)"
                class="tile-action tile-edit inline-svg__item"
              ></div>
              <div
                [inlineSVG]="'/assets/img/icons/delete.svg'"
                (click)="triggerDelete(user)"
                class="tile-action tile-delete inline-svg__item"
              ></div>
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="isEmpty()">
          <td colspan="4">
            {{ 'project.detail.settings.users.table.empty' | translate }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div
  [hidden]="loading() || !projectUsers()?.length"
  class="projects-settings__users__wrapper__content"
  *appVersion="2"
>
  <ul class="head">
    <li>
      <span>
        {{ 'project.detail.settings.users.table.name' | translate }}
      </span>
      <span>
        @if (project()) {
          {{ 'project.list.filter.role.title' | translate }}
        } @else {
          {{ 'project.detail.settings.users.table.role' | translate }}
        }
      </span>
      <span>
        {{ 'project.detail.settings.users.table.phone' | translate }}
      </span>
      <span>
        {{ 'project.detail.settings.users.table.last_event' | translate }}
      </span>
      <span></span>
    </li>
  </ul>

  <ul class="body">
    <li *ngFor="let user of projectUsers()">
      <span
        class="user-profile"
        [attr.aria-label]="
          'project.detail.settings.users.table.name' | translate
        "
      >
        <span>
          <app-avatar [projectUser]="user"></app-avatar>
        </span>
        <span>
          <span>
            {{ user.firstName }}
            {{ user.lastName }}
          </span>
          <span>
            {{ user.user.email }}
          </span>
        </span>
      </span>
      <span
        class="user-role"
        [attr.aria-label]="
          'project.detail.settings.users.table.role' | translate
        "
      >
        <ng-container
          *ngFor="let permission of user.sortedPermissions | keyvalue"
        >
          <span
            *ngIf="permission.value.length"
            [hidden]="
              user.sortedPermissions.account_admin.length &&
              permission.key != 'account_admin'
            "
            [class]="permission.key"
          >
            <span>
              <span
                [inlineSVG]="'/assets/v2/img/icons/' + permission.key + '.svg'"
              ></span>
              <span>
                {{
                  'project.detail.settings.users.table.type.' + permission.key
                    | translate
                }}
              </span>
            </span>
            <span class="number" *ngIf="editAdmins()">{{
              permission.key === 'account_admin'
                ? ('project.detail.settings.users.table.type.account_admin.all'
                  | translate)
                : getNumberOfProjects(permission.key, permission.value)
            }}</span>
          </span>
        </ng-container>
      </span>
      <span
        [attr.aria-label]="
          'project.detail.settings.users.table.phone' | translate
        "
      >
        <span *ngIf="user.phoneNumber">+{{ user.phoneNumber }}</span>
      </span>
      <span
        class="last-event"
        [attr.data-th]="
          'project.detail.settings.users.table.last_event' | translate
        "
      >
        <span *ngIf="user.lastLogEntry">
          {{ user.lastLogEntry.executedAt | date: 'mediumDate' }}
          <span>{{ user.lastLogEntry.executedAt | date: 'shortTime' }}</span>
        </span>
        <span *ngIf="user.lastLogEntry">
          {{ user.lastLogEntry.entityType | translate }}
          {{ user.lastLogEntry.action | translate }}
        </span>
        <span *ngIf="!user.lastLogEntry">-</span>
      </span>
      <span class="actions" *ngIf="projectUser">
        <ng-container *ngIf="user.id !== projectUser.id && hasPermission(user)">
          <a (click)="triggerDelete(user)" class="tile-action tile-delete">
            {{
              'project.detail.settings.users.table.actions.delete' | translate
            }}
          </a>
          <a (click)="triggerEdit(user)" class="tile-action tile-edit">
            {{ 'project.detail.settings.users.table.actions.edit' | translate }}
          </a>
        </ng-container>
      </span>
    </li>
  </ul>
</div>

<div class="list" [hidden]="!isEmpty() || loading()">
  <div class="no-results">
    {{ 'project.detail.settings.users.table.empty' | translate }}
  </div>
</div>
