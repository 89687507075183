<div
  class="notices__data__filter form-group"
  [formGroup]="form"
  *appVersion="1"
>
  <div class="notices__data__filter__checkboxes">
    <div class="checkbox">
      <input type="checkbox" id="statusOpen" formControlName="open" />
      <label for="statusOpen">{{
        'smart_reporting.notices.status.open' | translate
      }}</label>
    </div>
    <div class="checkbox">
      <input
        type="checkbox"
        id="statusInProgress"
        formControlName="inProgress"
      />
      <label for="statusInProgress">{{
        'smart_reporting.notices.status.in_progress' | translate
      }}</label>
    </div>
    <div class="checkbox">
      <input type="checkbox" id="statusClosed" formControlName="closed" />
      <label for="statusClosed">{{
        'smart_reporting.notices.status.closed' | translate
      }}</label>
    </div>
    <div class="checkbox">
      <input type="checkbox" id="statusDeleted" formControlName="deleted" />
      <label for="statusDeleted">{{
        'smart_reporting.notices.status.deleted' | translate
      }}</label>
    </div>
  </div>

  <ng-container *ngIf="project">
    <a
      appAccess
      role="manager"
      [project]="project"
      routerLink="/smart-reporting/notices/create/{{ projectSlug }}"
      class="btn notices__data__create"
    >
      {{ 'smart_reporting.notices.create' | translate }}
    </a>
  </ng-container>
  <a
    href="javascript:void(0)"
    (click)="exportCsv()"
    class="btn notices__data__export"
  >
    {{ 'smart_reporting.notices.export' | translate }}
  </a>
</div>

<table
  class="smart-reporting__notices__table"
  *appVersion="1"
  infiniteScroll
  (scrolled)="loadNotices()"
  [infiniteScrollContainer]="'div.app__content'"
  [infiniteScrollDisabled]="notices.length == totalItems"
  [fromRoot]="true"
>
  <thead>
    <tr>
      <th width="30%">
        {{ 'smart_reporting.notices.table.theme' | translate }}
      </th>
      <th width="20%">
        {{ 'smart_reporting.notices.table.name' | translate }}
      </th>
      <th width="15%">
        {{ 'smart_reporting.notices.table.date' | translate }}
      </th>
      <th width="10%">
        {{ 'smart_reporting.notices.table.status' | translate }}
      </th>
      <th width="10%">
        {{ 'smart_reporting.notices.table.followers' | translate }}
      </th>
      <th width="15%">
        {{ 'smart_reporting.notices.table.response_date' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="!loading && notices && notices.length === 0">
      <td colspan="6">
        {{ 'smart_reporting.notices.table.empty' | translate }}
      </td>
    </tr>
    <ng-container *ngIf="notices">
      <tr
        *ngFor="let notice of notices"
        class="smart-reporting__notice"
        routerLink="/smart-reporting/notices/{{ notice.id }}"
      >
        <td>{{ notice.theme.title }}</td>
        <td>{{ notice.name }}</td>
        <td>{{ notice.createdAt | date: 'd MMM y' }}</td>
        <td>
          <span
            class="label"
            [class.label-primary]="
              SmartReportingNoticeStatus.OPEN === notice.status
            "
            [class.label-success]="
              SmartReportingNoticeStatus.CLOSED === notice.status
            "
            [class.label-info]="
              SmartReportingNoticeStatus.IN_PROGRESS === notice.status
            "
            [class.label-secondary]="
              SmartReportingNoticeStatus.DELETED === notice.status
            "
            >{{
              'smart_reporting.notices.table.status.' + notice.status
                | translate
            }}</span
          >
        </td>
        <td>{{ notice.amountOfSubscribers }}</td>
        <td>
          {{
            notice.lastResponseDate
              ? (notice.lastResponseDate | date: 'd MMM y')
              : '-'
          }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<ng-container *appVersion="1">
  <app-loader *ngIf="loading"></app-loader>
</ng-container>

<app-data-filter-v2
  *appVersion="2"
  (filterChanged)="updateFilter($event)"
  [sortItems]="sortItems"
  [customFilters]="transformToFilters()"
  [searchLabel]="'smart_reporting.notices.search'"
  [sortLabel]="'project.list.sort'"
>
  <div class="smart-reporting__content">
    <div class="smart-reporting__wrapper">
      <p *ngIf="notices && notices.length">
        {{
          'smart_reporting.notices.result.title'
            | translate: { total: totalItems }
        }}
      </p>

      <div
        *ngIf="notices.length"
        class="smart-reporting__list notices"
        infiniteScroll
        (scrolled)="loadNotices()"
        [infiniteScrollContainer]="'div.app__content'"
        [infiniteScrollDisabled]="notices.length == totalItems"
        [fromRoot]="true"
      >
        <ul class="head">
          <li [class.isCustomer]="!project">
            <span>
              {{ 'smart_reporting.notices.table.status' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.date' | translate }}
            </span>
            <span *ngIf="!project">
              {{ 'smart_reporting.notices.table.project' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.theme' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.name' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.followers' | translate }}
            </span>
            <span>
              {{ 'smart_reporting.notices.table.response_date' | translate }}
            </span>
          </li>
        </ul>

        <ul class="body">
          <li
            *ngFor="let notice of notices"
            [class.isCustomer]="!project"
            routerLink="{{ notice.id }}"
          >
            <span>
              <span
                class="label"
                [class.label-primary]="
                  SmartReportingNoticeStatus.OPEN === notice.status
                "
                [class.label-success]="
                  SmartReportingNoticeStatus.CLOSED === notice.status
                "
                [class.label-info]="
                  SmartReportingNoticeStatus.IN_PROGRESS === notice.status
                "
                [class.label-secondary]="
                  SmartReportingNoticeStatus.DELETED === notice.status
                "
                >{{
                  'smart_reporting.notices.table.status.' + notice.status
                    | translate
                }}</span
              >
            </span>
            <span class="datetime">
              <span class="date">{{
                notice.createdAt | date: 'mediumDate'
              }}</span>
              <span class="time">{{
                notice.createdAt | date: 'shortTime'
              }}</span>
            </span>
            <span
              [attr.aria-label]="
                'smart_reporting.notices.table.project' | translate
              "
              *ngIf="!project"
            >
              {{ notice.project.name }}
            </span>
            <span
              [attr.aria-label]="
                'smart_reporting.notices.table.theme' | translate
              "
            >
              {{ notice.theme.title }}
            </span>
            <span
              [attr.aria-label]="
                'smart_reporting.notices.table.name' | translate
              "
            >
              {{ notice.name }}
            </span>
            <span
              [attr.aria-label]="
                'smart_reporting.notices.table.followers' | translate
              "
              >{{ notice.amountOfSubscribers }}</span
            >
            <span
              class="datetime"
              [attr.aria-label]="
                'smart_reporting.notices.table.response_date' | translate
              "
            >
              <span class="date">{{
                notice.lastResponseDate | date: 'mediumDate'
              }}</span>
              <span class="time">{{
                notice.lastResponseDate | date: 'shortTime'
              }}</span>
            </span>
          </li>
        </ul>
      </div>
      <div class="list" [hidden]="loading || notices">
        <div class="no-results">
          {{ 'ticket_list.table.no_results' | translate }}
        </div>
      </div>
      <app-loader *ngIf="loading"></app-loader>
    </div>
  </div>
</app-data-filter-v2>
