<div class="bouwapp-header" [hidden]="mode !== 'configure'">
  <div class="bouwapp-header__text">
    <h1>
      {{ 'project.detail.satisfaction_monitor.configure.title' | translate }}
    </h1>
  </div>
  <div class="bouwapp-header__buttons" *appVersion="2">
    <button
      appAccess
      role="manager"
      [project]="project"
      [appLoading]="loading"
      (click)="cancel()"
      class="delete"
    >
      {{ 'project.detail.phases.edit.cancel' | translate }}
    </button>
    <button
      appAccess
      role="manager"
      [project]="project"
      [appLoading]="loading"
      (click)="configure()"
      class="primary"
    >
      {{
        (editing
          ? 'project.detail.events.save'
          : 'project.detail.satisfaction_monitor.configure.themes.activate'
        ) | translate
      }}
    </button>
  </div>
</div>

<div class="bouwapp-header" [hidden]="mode !== 'results'">
  <div class="bouwapp-header__text">
    <h1>
      {{ 'project.detail.satisfaction_monitor.results.title' | translate }}
    </h1>
    <app-success-message [onScreen]="true" [hidden]="!sendingPush">
      {{ 'project.detail.satisfaction_monitor.results.push_sent' | translate }}
    </app-success-message>
  </div>
  <div class="bouwapp-header__buttons">
    <button
      *appVersion="2"
      class="tertiary"
      (click)="sendNotifications.open()"
      appAccess
      role="manager"
      [project]="project"
    >
      <span class="icon" inlineSVG="/assets/img/icons/bell.svg"></span>
      <span>{{ 'project.detail.feedback.editor.top.push' | translate }}</span>
    </button>
    <button
      (click)="switchToConfigure()"
      class="secondary"
      appAccess
      role="manager"
      [project]="project"
    >
      {{ 'project.detail.satisfaction_monitor.results.configure' | translate }}
    </button>
    <button
      (click)="exportAllGraphs()"
      [hidden]="loadingResults"
      class="primary"
    >
      {{ 'project.detail.satisfaction_monitor.results.export_all' | translate }}
    </button>
  </div>
</div>

<section class="configure-satisfaction-monitor" [hidden]="mode !== 'configure'">
  <div *ngIf="themes">
    <div class="alert alert-info">
      {{ 'project.detail.satisfaction_monitor.configure.warning' | translate }}
    </div>

    <div class="alert alert-danger" [hidden]="!error">
      {{ 'project.detail.satisfaction_monitor.configure.error' | translate }}
    </div>

    <div class="themes">
      <h4>
        {{
          'project.detail.satisfaction_monitor.configure.themes.title'
            | translate
        }}
        <span class="count" *appVersion="1"
          >{{ project.ratingThemes.length }}/{{ MAX_THEMES }}</span
        >
      </h4>
      <p *appVersion="1">
        {{
          'project.detail.satisfaction_monitor.configure.themes.introduction'
            | translate
        }}
      </p>
      <p
        *appVersion="2"
        [innerHTML]="
          'project.detail.satisfaction_monitor.configure.themes.description'
            | translate
        "
      ></p>
      <p
        *appVersion="1"
        class="survey-notice"
        [innerHTML]="
          'project.detail.satisfaction_monitor.configure.themes.survey_notice'
            | translate
        "
      ></p>

      <div>
        <div class="search bouwapp-toolbar">
          <input
            type="search"
            class="input-lg"
            [attr.placeholder]="
              'project.detail.satisfaction_monitor.configure.themes.search'
                | translate
            "
            [formControl]="searchControl"
            appAccess
            role="manager"
            [project]="project"
            property="readonly"
          />
        </div>
        <div class="items" [class.empty]="themes.length === 0">
          <div
            class="item"
            *ngFor="let item of themes"
            [class.required]="item.required"
            [class.active]="item.active"
            (click)="toggleTheme(item)"
            appAccess
            role="manager"
            [project]="project"
            property="disabled"
          >
            <div class="text">
              <span class="title"
                >{{ item.title }}
                <span [hidden]="!item.required">{{
                  'project.detail.satisfaction_monitor.configure.themes.required'
                    | translate
                }}</span></span
              >
            </div>
          </div>
          <p [hidden]="themes.length > 0">
            {{
              'project.detail.satisfaction_monitor.configure.themes.no_results'
                | translate: { text: search }
            }}
          </p>
        </div>
      </div>

      <button
        *appVersion="1"
        appAccess
        role="manager"
        [project]="project"
        [appLoading]="loading"
        (click)="configure()"
        class="btn btn-lg themes__button"
      >
        {{
          (editing
            ? 'project.detail.satisfaction_monitor.configure.themes.update'
            : 'project.detail.satisfaction_monitor.configure.themes.activate'
          ) | translate
        }}
      </button>
    </div>
  </div>
</section>

<section class="satisfaction-monitor" [hidden]="mode !== 'results'">
  <div>
    <div class="settings" *appVersion="1">
      <app-period [formControl]="periodControl"></app-period>
    </div>

    <app-data-filter
      *appVersion="1"
      [demography]="demography"
      (filter)="handleFilter($event)"
    ></app-data-filter>
    <app-data-filter-v2
      *appVersion="2"
      [hasSelectAll]="true"
      [hasShowMore]="true"
      (filterChanged)="handleFilter($event)"
      [customFilters]="customFilters"
    >
      <div class="satisfaction-monitor__list">
        <div class="settings" *appVersion="2">
          <app-period
            [formControl]="periodControl"
            [hasButton]="true"
            [hasDay]="false"
          ></app-period>
        </div>
        <app-loader [hidden]="results"></app-loader>
        <div *ngIf="results">
          <ng-container *appVersion="1">
            <app-chart
              *ngFor="let result of [results[0]]"
              [csvExport]="false"
              [title]="result.title"
              [stripZeroValues]="true"
              [tooltip]="determineChartTooltipTitle"
              [data]="result.data"
              [type]="'line'"
              [maximum]="5"
            ></app-chart>
          </ng-container>
          <section class="charts" *appVersion="2">
            <app-loader [text]="false" *ngIf="loading"></app-loader>
            <ng-container *ngIf="!loading">
              <div class="chart" *ngIf="chart">
                <h4>
                  {{ results[0].title }}
                </h4>
                <div class="chart__inner">
                  <canvas
                    baseChart
                    [type]="chart.config.type"
                    [options]="chart.config.options"
                    [data]="chart.config.data"
                  ></canvas>
                </div>
                <div class="checkboxes">
                  <div
                    class="checkbox"
                    *ngFor="
                      let dataset of chart.config.data.datasets;
                      let i = index
                    "
                  >
                    <input type="checkbox" [checked]="!dataset.hidden" />
                    <label (click)="toggleDataset(i)">
                      {{ dataset.label }}
                    </label>
                  </div>
                </div>
              </div>
            </ng-container>
          </section>
          <app-chart
            [csvExport]="false"
            [title]="results[1].title"
            [data]="results[1].data"
            [type]="'satisfaction-distribution'"
          ></app-chart>
        </div>
      </div>
    </app-data-filter-v2>

    <ng-container *appVersion="1">
      <br />
      <br />
      <hr />

      <div
        class="push-notification"
        appAccess
        role="manager"
        [project]="project"
      >
        <h3>
          {{ 'project.detail.satisfaction_monitor.results.push' | translate }}
        </h3>
        <div class="row">
          <div class="col-sm-6">
            <p>
              {{
                'project.detail.satisfaction_monitor.results.instruction'
                  | translate
              }}
              <a href="javascript:void(0)" (click)="toggleTargetPicker()">{{
                'project.detail.satisfaction_monitor.results.select_targets'
                  | translate
              }}</a>
            </p>

            <div class="targets" [class.not-selected]="!showTargetPicker">
              <app-target-picker
                [project]="project"
                [multiple]="true"
                [label]="false"
                [formControl]="targetPicker"
              ></app-target-picker>
            </div>

            <div class="push">
              <button
                (click)="sendPushNotification()"
                class="btn btn-primary btn-lg"
                [attr.disabled]="!canPushRating() || sendingPush ? true : null"
              >
                {{
                  showTargetPicker
                    ? ('project.detail.satisfaction_monitor.results.send_push'
                      | translate)
                    : ('project.detail.satisfaction_monitor.results.send_push_to_users'
                      | translate: { starredBy: project.starredBy })
                }}
              </button>
              <div class="disabled" [hidden]="canPushRating()">
                {{
                  'project.detail.satisfaction_monitor.results.timeout'
                    | translate: { timeTillPush: getTimeTillRatingPush() }
                }}
              </div>
            </div>

            <div class="alert alert-danger" [hidden]="!sendingPushFailed">
              {{
                'project.detail.satisfaction_monitor.results.error' | translate
              }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <app-modal
    #sendNotifications
    [title]="'project.detail.feedback.editor.top.push' | translate"
  >
    <div slot="header">
      {{
        'project.detail.satisfaction_monitor.results.instruction' | translate
      }}
    </div>
    <div class="targets">
      <app-target-picker
        [project]="project"
        [multiple]="true"
        [label]="false"
        [formControl]="targetPicker"
      ></app-target-picker>
    </div>
    <button
      (click)="sendPushNotification()"
      class="button--black-white satisfaction-monitor__modal__button"
      [attr.disabled]="!canPushRating() || sendingPush ? true : null"
    >
      <span class="icon" inlineSVG="/assets/img/icons/bell.svg"></span>
      <p>
        {{
          'project.detail.satisfaction_monitor.results.send_push' | translate
        }}
      </p>
    </button>
  </app-modal>
</section>
