import { CommonModule } from '@angular/common';
import { Component, OnInit, viewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartDataset,
  ChartType,
} from 'chart.js';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ModalComponent } from 'src/app/components/modal.component';
import { StatisticsType } from 'src/app/enums/statistics-type';
import * as moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { PeriodComponent } from 'src/app/components/period.component';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { StatisticComponent } from 'src/app/components/statistic.component';
import { UpdatesTileComponent } from '../updates/tile.component';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { Router, RouterModule } from '@angular/router';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { Map } from 'mapbox-gl';
import * as mapboxgl from 'mapbox-gl';
import { Target } from 'src/app/interfaces/target';
import { ColorHelper } from 'src/app/shared/color-helper';
import { ThemeService } from 'src/app/services/theme.service';
import { VersionDirective } from 'src/app/directives/version.directive';

@Component({
  selector: 'app-statistics',
  standalone: true,
  templateUrl: './statistics.component.html',
  imports: [
    TranslateModule,
    ModalComponent,
    VersionDirective,
    InlineSVGModule,
    RouterModule,
    NgxMapboxGLModule,
    PeriodComponent,
    UpdatesTileComponent,
    StatisticComponent,
    CommonModule,
    ReactiveFormsModule,
    InlineSVGModule,
    BaseChartDirective,
  ],
})
export class StatisticsComponent implements OnInit {
  public project: Project;
  public readonly modal = viewChild<ModalComponent>(ModalComponent);
  public modalTitle: string;
  public modalDesc: string;
  public activeUsersChart: any;
  public followersChart: any;
  public reactionsChart: any;
  public engagementChart: any;
  public ratingChart: any;
  public interactionsChart: any;
  public periodControl: FormControl;
  public charts: any[] = [];
  public reactionsChartData: ChartData<'doughnut'>;
  public reactionLabels: string[];
  public interactionsChartData: ChartData<'bar'>;
  public update: Update;
  public targets: Target[];
  public labels: {};
  public StatisticsType = StatisticsType;
  public tip: number;
  public mapFilter: any[];
  public map: Map;
  public userLocations: any[];
  public defaultColors = ['#f5b049', '#3373ea', '#fc5447', '#50c878'];
  public reactionColors = [
    '#3373ea',
    '#f5b049',
    '#49da16',
    '#a800f2',
    '#fc5447',
  ];
  public interactionColors = {
    update_open: '#3373ea',
    update_view: '#f5b049',
    notification_clicked: '#50c878',
    poll_react: '#a800f2',
    update_react: '#fc5447',
  };

  constructor(
    private translateService: TranslateService,
    private projectDataBusService: ProjectDataBusService,
    private themeService: ThemeService,
    private formBuilder: FormBuilder,
  ) {
    this.checkVersion();
    this.projectDataBusService.projectObservable.subscribe((project) => {
      this.project = project;
    });
  }

  async checkVersion() {
    if ((await this.themeService.getVersion()) === 1) {
      window.history.back();
    }
  }

  ngOnInit() {
    this.tip = Math.ceil(Math.random() * 3);
    this.createControls();
    this.getLatestUpdate();
  }

  getLatestUpdate() {
    this.update = {
      publicationDate: moment().format().toString(),
      content: this.translateService.instant(
        'project.detail.statistics.new.update.content',
      ),
      sendPush: false,
      pushDateTime: null,
      pushStatus: null,
      pushCount: 0,
      ratingMonitorPush: null,
      attachments: [
        {
          filePathThumbnails: { medium: '/assets/img/favicon_bouwapp.ico' },
        },
        {
          filePathThumbnails: { medium: '/assets/img/favicon_sitepodium.ico' },
        },
      ],
      poll: {
        choices: [
          { text: 'A', filePath: null, filePathThumbnails: null, votes: 60 },
          { text: 'B', filePath: null, filePathThumbnails: null, votes: 40 },
        ],
        title: this.translateService.instant('poll.title'),
      },
      viewCount: 333,
      updateOpenCount: 0,
      customer: {
        name: this.translateService.instant('app.title'),
        id: 1,
        projects: [],
        enabledModules: [],
        logo: null,
      },
    };
  }

  generateChartConfig(
    type: ChartType,
    data: ChartData,
    title: string,
    showLegend: boolean,
    stepSize: number = 5,
    max?: number,
    stacked?: boolean,
  ): any {
    return {
      config: {
        options: {
          responsive: true,
          maintainAspectRatio: type === 'doughnut',
          plugins: {
            tooltip: {
              backgroundColor: '#ffffff',
              titleColor: '#212121',
              bodyColor: '#212121',
              bodyFont: { weight: 'normal', family: 'Mukta' },
              titleFont: { weight: 'bold', family: 'Mukta' },
              cornerRadius: 5,
              borderColor: '#e9e9e9',
              borderWidth: 1,
              padding: 8,
              boxPadding: 8,
            },
            legend: {
              display: showLegend,
              position: 'bottom',
              align: 'start',
              labels: {
                font: {
                  family: 'Mukta',
                  size: 14,
                  weight: 600,
                },
                color: '#212121',
                boxWidth: 20,
                boxHeight: 20,
                padding: 20,
                borderRadius: 5,
                usePointStyle: true,
                pointStyleWidth: 20,
              },
              onHover: (event) => {
                const target = event.native.target as HTMLElement;
                if (target) target.style.cursor = 'pointer';
              },
              onLeave: (event) => {
                const target = event.native.target as HTMLElement;
                if (target) target.style.cursor = 'auto';
              },
            },
            title: {
              display: false,
              text: '',
            },
          },
          scales: {
            x: {
              display: type === 'doughnut' ? false : true,
              stacked: stacked ? true : false,
              ticks: {
                autoSkip: true,
                autoSkipPadding: type === 'line' ? 10 : 0,
              },
              border: {
                width: 0,
              },
              grid: {
                display: false,
              },
            },
            y: {
              afterDataLimits: (axis) => {
                //fix for not cutting off line and points on min and max
                let value = 2.5;
                if (stepSize === 1) value = 0.25;
                axis.max += value;
                axis.min -= value;
              },
              display: type === 'doughnut' ? false : true,
              stacked: stacked ? true : false,
              beginAtZero: true,
              ticks: {
                stepSize: stepSize,
              },
              max: max,
              min: 0,
              border: {
                width: 0,
              },
              grid: {
                display: false,
              },
            },
            y1: {
              beginAtZero: true,
              display: title === StatisticsType.FOLLOWERS ? true : false,
              ticks: {
                stepSize: 5,
              },
              position: 'right',
              max: this.periodControl.value.type === 'month' ? 125 : 75,
              min: 0,
              afterDataLimits: (axis) => {
                //fix for adjusting to the other scale
                axis.max += 4;
                axis.min -= 4;
              },
              border: {
                width: 0,
              },
              grid: {
                display: false,
              },
            },
          },
        },
        type: type,
        data: data,
      },
    };
  }

  createControls() {
    this.periodControl = this.formBuilder.control({
      type: 'week',
      start: moment().subtract(7, 'days').format(),
      end: moment().format(),
    });

    this.periodControl.valueChanges.subscribe((value) => {
      if (this.periodControl.valid) {
        this.initCharts();
      }
    });
  }

  mapLoaded(event: any) {
    this.map = event.target;
    this.userLocations = Array.from({ length: 100 }, () => ({
      latitude: (Math.random() * (71.2 - 35.0) + 35.0).toFixed(6),
      longitude: (Math.random() * (40.0 - -10.0) - 10.0).toFixed(6),
    }));
    const marker = new mapboxgl.Marker({ color: '#F5B049' })
      .setLngLat([this.project.locationLong, this.project.locationLat])
      .addTo(this.map);
    this.updateMapLayers();
  }

  updateMapLayers() {
    const length =
      this.mapFilter.filter((filter) => filter.isChecked).length * 20;

    const features = this.userLocations.slice(0, length).map((location) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [location.longitude, location.latitude],
      },
    })) as any;

    if (this.map.getLayer('heatmap-layer'))
      this.map.removeLayer('heatmap-layer');
    if (this.map.getSource('users-location'))
      this.map.removeSource('users-location');

    this.map.addSource('users-location', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: features,
      },
    });

    this.map.addLayer({
      id: 'heatmap-layer',
      type: 'heatmap',
      source: 'users-location',
    });

    if (features.length) {
      const bounds = new mapboxgl.LngLatBounds();
      features.forEach((feature) => {
        bounds.extend(feature.geometry.coordinates);
      });

      this.map.fitBounds(bounds, { padding: 25, animate: false, maxZoom: 10 });
    } else {
      this.map.setCenter([4.9041, 52.3676]).setZoom(3);
    }
  }

  initCharts() {
    const activeUsersData = this.loadActiveUsersData();
    this.activeUsersChart = this.generateChartConfig(
      'bar',
      activeUsersData,
      StatisticsType.ACTIVE_USERS,
      true,
      null,
      null,
      true,
    );

    const followersData = this.loadFollowersData();
    this.followersChart = this.generateChartConfig(
      'bar',
      followersData,
      StatisticsType.FOLLOWERS,
      true,
      1,
      5,
    );

    const reactionsData = this.loadReactionsData();
    this.reactionsChart = this.generateChartConfig(
      'doughnut',
      reactionsData,
      StatisticsType.REACTIONS,
      false,
    );

    const engagementData = this.loadEngagementData();
    this.engagementChart = this.generateChartConfig(
      'line',
      engagementData,
      StatisticsType.ENGAGEMENT,
      true,
      null,
      100,
    );

    const ratingData = this.loadRatingData();
    this.ratingChart = this.generateChartConfig(
      'line',
      ratingData,
      StatisticsType.RATING,
      true,
      1,
      5,
    );

    const interactionsData = this.loadInteractionsData();
    this.interactionsChart = this.generateChartConfig(
      'bar',
      interactionsData,
      StatisticsType.INTERACTIONS,
      true,
      null,
      null,
      true,
    );
  }

  updateMapFilter(event, index) {
    this.mapFilter[index].isChecked = event.target.checked;
    this.updateMapLayers();
  }

  loadInteractionsData(): ChartData {
    let labels;
    if (this.periodControl.value.type === 'total') {
      labels = Array.from({ length: 12 }).map((_, i) =>
        moment()
          .subtract(12 - i, 'weeks')
          .format('[Week] WW'),
      );
    } else {
      const amountOfDays = moment(this.periodControl.value.end)
        .startOf('day')
        .diff(moment(this.periodControl.value.start).startOf('day'), 'days');
      labels = Array.from({ length: amountOfDays }).map((_, i) =>
        moment()
          .subtract(amountOfDays - i, 'days')
          .format('MM-DD'),
      );
    }
    const interactions = {
      interactions: {},
    };

    const interactionsLabels = [
      'update_open',
      'update_view',
      'notification_clicked',
      'poll_react',
      'update_react',
    ];
    for (let i = 0; i < labels.length; i++) {
      interactions['interactions'][labels[i]] = {};
      for (let j = 0; j < interactionsLabels.length; j++) {
        interactions['interactions'][labels[i]][interactionsLabels[j]] =
          Math.floor(Math.random() * 101);
      }
    }

    this.mapFilter = interactionsLabels.map((label) => ({
      label: label,
      isChecked: true,
    }));

    return this.generateChartData(labels, interactions, ['bar']);
  }

  loadReactionsData(): ChartData {
    const labels = ['like', 'awesome', 'idea', 'worried', 'dislike'];
    this.reactionLabels = labels;
    const data = {
      reactions: {},
    };
    for (let i = 0; i < labels.length; i++) {
      data['reactions'][labels[i]] = Math.floor(Math.random() * 101);
    }

    return this.generateChartData(
      labels,
      data,
      ['doughnut'],
      this.reactionColors,
    );
  }

  loadRatingData(): ChartData {
    let labels;
    if (this.periodControl.value.type === 'total') {
      labels = Array.from({ length: 12 }).map((_, i) =>
        moment()
          .subtract(12 - i, 'weeks')
          .format('[Week] WW'),
      );
    } else {
      const amountOfDays = moment(this.periodControl.value.end)
        .startOf('day')
        .diff(moment(this.periodControl.value.start).startOf('day'), 'days');
      labels = Array.from({ length: amountOfDays }).map((_, i) =>
        moment()
          .subtract(amountOfDays - i, 'days')
          .format('MM-DD'),
      );
    }

    const data = {
      rating: {},
      average: {},
    };

    for (let i = 0; i < labels.length; i++) {
      data['rating'][labels[i]] = (Math.random() * (4.8 - 4.2) + 4.2).toFixed(
        1,
      );
      data['average'][labels[i]] = 4.5;
    }

    return this.generateChartData(labels, data, ['line']);
  }

  loadEngagementData(): ChartData {
    let labels;
    if (this.periodControl.value.type === 'total') {
      labels = Array.from({ length: 12 }).map((_, i) =>
        moment()
          .subtract(12 - i, 'weeks')
          .format('[Week] WW'),
      );
    } else {
      const amountOfDays = moment(this.periodControl.value.end)
        .startOf('day')
        .diff(moment(this.periodControl.value.start).startOf('day'), 'days');
      labels = Array.from({ length: amountOfDays }).map((_, i) =>
        moment()
          .subtract(amountOfDays - i, 'days')
          .format('MM-DD'),
      );
    }

    const data = {
      engagement: {},
      average: {},
      top: {},
    };

    for (let i = 0; i < labels.length; i++) {
      data['engagement'][labels[i]] = (Math.random() * (60 - 40) + 40).toFixed(
        1,
      );
      data['average'][labels[i]] = 50;
      data['top'][labels[i]] = 75;
    }

    return this.generateChartData(labels, data, ['line', 'line', 'line']);
  }

  loadFollowersData(): ChartData {
    let labels;
    if (this.periodControl.value.type === 'total') {
      labels = Array.from({ length: 12 }).map((_, i) =>
        moment()
          .subtract(12 - i, 'weeks')
          .format('[Week] WW'),
      );
    } else {
      const amountOfDays = moment(this.periodControl.value.end)
        .startOf('day')
        .diff(moment(this.periodControl.value.start).startOf('day'), 'days');
      labels = Array.from({ length: amountOfDays }).map((_, i) =>
        moment()
          .subtract(amountOfDays - i, 'days')
          .format('MM-DD'),
      );
    }

    const data = {
      followersGained: {},
      totalFollowers: {},
    };

    for (let i = 0; i < labels.length; i++) {
      data['totalFollowers'][labels[i]] =
        (data['totalFollowers'][labels[i - 1]] || 50) +
        Math.floor(Math.random() * 5);
      data['followersGained'][labels[i]] =
        data['totalFollowers'][labels[i]] -
          data['totalFollowers'][labels[i - 1]] || 0;
    }

    return this.generateChartData(labels, data, ['bar', 'line']);
  }

  loadActiveUsersData(): ChartData {
    let labels;
    if (this.periodControl.value.type === 'total') {
      labels = Array.from({ length: 12 }).map((_, i) =>
        moment()
          .subtract(12 - i, 'weeks')
          .format('[Week] WW'),
      );
    } else {
      const amountOfDays = moment(this.periodControl.value.end)
        .startOf('day')
        .diff(moment(this.periodControl.value.start).startOf('day'), 'days');
      labels = Array.from({ length: amountOfDays }).map((_, i) =>
        moment()
          .subtract(amountOfDays - i, 'days')
          .format('MM-DD'),
      );
    }

    const data = {
      notFollowers: {},
      followers: {},
      unknown: {},
    };

    for (let i = 0; i < labels.length; i++) {
      data['notFollowers'][labels[i]] = Math.floor(Math.random() * 100);
      data['followers'][labels[i]] = Math.floor(Math.random() * 100);
      data['unknown'][labels[i]] = Math.floor(Math.random() * 100);
    }

    return this.generateChartData(labels, data, ['bar', 'bar', 'bar']);
  }

  generateChartData(
    labels: string[],
    data: any,
    types: ChartType[],
    colors?: string[],
  ): ChartData {
    let dataSets: ChartDataset[] = [];

    if (!colors) {
      colors = ColorHelper.generateColorStream(
        this.defaultColors,
        6,
        Object.keys(data).length,
      );
    }

    let index = 0;
    for (const key in data) {
      if (key === 'interactions') {
        const interactions = data[key];
        dataSets = Object.keys(interactions[Object.keys(interactions)[0]]).map(
          (key) => {
            return {
              label: this.translateService.instant(
                'project.detail.statistics.new.label.' + key,
              ),
              data: Object.keys(interactions).map(
                (date) => +interactions[date][key],
              ),
              backgroundColor: this.interactionColors[key],
            };
          },
        );
      } else {
        dataSets.push({
          type: types[index],
          data: Object.values(data[key]),
          label: this.translateService.instant(
            'project.detail.statistics.new.label.' + key,
          ),
          fill: false,
          borderDash: key === 'top' || key === 'average' ? [5, 5] : [],
          borderWidth: types[index] === 'bar' ? 0 : 3,
          backgroundColor:
            types[index] != 'doughnut'
              ? colors[index]
              : (context) => {
                  return colors[context.dataIndex];
                },
          borderColor:
            types[index] != 'doughnut'
              ? colors[index]
              : (context) => {
                  return colors[context.dataIndex];
                },
          tension: 0.2,
          pointRadius: key === 'top' || key === 'average' ? 0 : 2,
          order: types[index] === 'line' ? 0 : 1,
          minBarLength: 3,
          yAxisID: key === 'totalFollowers' ? 'y1' : 'y',
        });
      }
      if (types[index] === 'line') {
        dataSets[index].stack = 'Stack ' + index;
      } else if (types[index] === 'doughnut') {
        labels = labels.map(
          (label) =>
            (label = this.translateService.instant(
              'project.detail.statistics.new.label.' + label,
            )),
        );
      }
      index++;
    }

    return {
      labels: labels,
      datasets: dataSets,
    };
  }

  openModal(key: string) {
    this.modalTitle = this.translateService.instant(
      `project.detail.statistics.new.stats.${key}`,
    );
    this.modalDesc = this.translateService.instant(
      `project.detail.statistics.new.stats.${key}.desc`,
    );
    this.modal().open();
  }

  handleUpdateClick(event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  export() {
    window.open('/assets/pdf/DEMO-Project-Insights-2.pdf', '_blank');
  }
}
