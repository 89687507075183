<form [formGroup]="filterForm">
  <!-- Search and Sort Section -->
  <div
    class="bouwapp-toolbar"
    [ngClass]="{ hasSorting: sortItems().length > 0 }"
    *ngIf="searchLabel()"
  >
    <div>
      <input
        [attr.placeholder]="searchLabel() | translate"
        type="search"
        class="search"
        formControlName="search"
        id="search"
      />
    </div>
    <div *ngIf="sortItems().length > 0">
      <select
        [attr.title]="sortLabel() | translate"
        id="sort"
        class="select select--m"
        formControlName="sort"
      >
        <option *ngFor="let sort of sortItems()" [ngValue]="sort">
          {{ sort.label | translate }}
        </option>
      </select>
    </div>
  </div>

  <section class="filter" [ngClass]="{ isHorizontal: isHorizontal() }">
    <div class="filter__wrapper">
      <div class="filter__title" *appVersion="2">
        <h3>{{ 'project.list.filter.title' | translate }}</h3>
      </div>
      <div formArrayName="customFilters">
        <div
          *ngFor="let filterGroup of filtersArray.controls; let i = index"
          [formGroupName]="i"
          class="filter__group"
        >
          <label class="filter__label"
            >{{ filterGroup.get('title').value | translate }}
            <span
              *ngIf="
                filterGroup.get('type').value === 'checkbox' && hasSelectAll()
              "
              (click)="selectAll(i)"
              >{{
                'projects.detail.update.edit.targets.select-all' | translate
              }}</span
            ></label
          >

          <!-- CHECKBOX Type -->
          <div
            *ngIf="filterGroup.get('type').value === 'checkbox'"
            class="checkboxes"
            formArrayName="filters"
          >
            <div
              *ngFor="
                let control of getFiltersArray(i)?.controls;
                let j = index
              "
              class="checkbox"
              [class.hidden]="hasShowMore() && j >= 5 && !showMore[i]"
            >
              <input
                type="checkbox"
                [id]="filterGroup.get('title').value + '_' + j"
                [formControlName]="j"
              />
              <label [for]="filterGroup.get('title').value + '_' + j">
                {{ customFilters()[i]?.filters[j]?.label | translate }}
                <span
                  *ngIf="customFilters()[i]?.filters[j]?.count !== undefined"
                >
                  ({{ customFilters()[i]?.filters[j]?.count }})
                </span>
              </label>
            </div>

            <button
              *ngIf="hasShowMore() && getFiltersArray(i).length > 5"
              (click)="toggleShowMore(i)"
              class="show-more"
            >
              {{
                showMore[i]
                  ? ('project.list.filter.show-less' | translate)
                  : ('project.list.filter.show-more'
                    | translate: { count: getFiltersArray(i).length - 5 })
              }}
            </button>
          </div>

          <!-- RADIO Type -->
          <div *ngIf="filterGroup.get('type').value === 'radio'" class="radios">
            <div
              *ngFor="
                let filterItem of customFilters()[i]?.filters;
                let j = index
              "
              class="radio"
            >
              <input
                type="radio"
                [id]="filterGroup.get('title').value + '_' + j"
                [formControl]="filterGroup.get('filters')"
                [value]="filterItem.key"
              />
              <label [for]="filterGroup.get('title').value + '_' + j">
                {{ filterItem.label | translate }}
              </label>
            </div>
          </div>

          <!-- DATE Type -->
          <div
            *ngIf="filterGroup.get('type').value === 'date'"
            class="dates"
            formArrayName="filters"
          >
            <ng-container
              *ngFor="
                let control of getFiltersArray(i)?.controls;
                let j = index
              "
            >
              <label>{{
                customFilters()[i]?.filters[j]?.label | translate
              }}</label>
              <input type="datetime-local" [formControlName]="j" />
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <section class="filter__content">
      <ng-content></ng-content>
    </section>
  </section>
</form>
