import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Survey } from 'src/app/interfaces/survey';
import { Project } from 'src/app/interfaces/project';
import { SurveyService } from 'src/app/services/survey.service';
import { ProjectDataBusService } from 'src/app/services/project-data-bus.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SurveyStatus } from 'src/app/enums/survey-status';
import { NgArrayPipesModule } from 'ngx-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDeleteComponent } from '../../../../components/confirm-delete.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AccessDirective } from '../../../../directives/access.directive';
import { Router, RouterLink } from '@angular/router';
import {
  NgIf,
  NgFor,
  NgClass,
  DatePipe,
  NgTemplateOutlet,
} from '@angular/common';
import { Module } from 'src/app/enums/module';
import { Subscription } from 'rxjs';
import { VersionDirective } from 'src/app/directives/version.directive';
import { DataFilterV2Component } from 'src/app/components/data-filter-v2.component';
import { Sort } from 'src/app/interfaces/sort';
import { ThemeService } from 'src/app/services/theme.service';
import { CustomFilterType } from 'src/app/enums/custom-filter-type';

@Component({
  selector: 'app-detail-feedback-list',
  templateUrl: './list.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    AccessDirective,
    ReactiveFormsModule,
    NgFor,
    NgClass,
    InlineSVGModule,
    ConfirmDeleteComponent,
    DatePipe,
    VersionDirective,
    TranslateModule,
    DataFilterV2Component,
    NgArrayPipesModule,
    NgTemplateOutlet,
  ],
})
export class DetailFeedbackListComponent implements OnInit, OnDestroy {
  project: Project;
  surveys: Survey[] = [];
  filteredSurveys: Survey[];
  orderBy: '-createdAt';
  filterBy = null;
  SurveyStatus = SurveyStatus;
  filterForm: FormGroup;
  projectSubscription: Subscription;
  private version: number = 1;

  public sortItems: Sort[] = [
    {
      field: 'title',
      label: 'projects.detail.feedback.list.sort.name.asc',
      direction: 'asc',
    },
    {
      field: 'title',
      label: 'projects.detail.feedback.list.sort.name.desc',
      direction: 'desc',
    },
    {
      field: 'createdAt',
      label: 'projects.detail.feedback.list.sort.date.asc',
      direction: 'asc',
    },
    {
      field: 'createdAt',
      label: 'projects.detail.feedback.list.sort.date.desc',
      direction: 'desc',
    },
  ];

  constructor(
    protected projectDataBusService: ProjectDataBusService,
    private surveyService: SurveyService,
    private themeService: ThemeService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.projectSubscription =
      this.projectDataBusService.projectObservable.subscribe((project) => {
        this.project = project;
        if (!this.project.enabledModules.includes(Module.SURVEY)) {
          this.router.navigate([
            `/projects/${this.project.slug}/feedback/non-active`,
          ]);
        }
      });
    this.createFilterForm();
  }

  async ngOnInit(): Promise<void> {
    this.version = await this.themeService.getVersion();

    this.load();
  }

  ngOnDestroy() {
    this.projectSubscription.unsubscribe();
  }

  transformToFilters() {
    return [
      {
        title: 'projects.detail.feedback.list.filter.placeholder',
        filters: [
          {
            label: 'projects.detail.feedback.list.filter.active',
            value: true,
            key: SurveyStatus.ACTIVE,
            count: this.surveys.filter(
              (survey) => survey.status === SurveyStatus.ACTIVE,
            ).length,
          },
          {
            label: 'projects.detail.feedback.list.filter.inactive',
            value: true,
            key: SurveyStatus.INACTIVE,
            count: this.surveys.filter(
              (survey) => survey.status === SurveyStatus.INACTIVE,
            ).length,
          },
        ],
        type: CustomFilterType.CHECKBOX,
      },
      {
        title: 'project.list.filter.date.title',
        type: CustomFilterType.DATE,
        filters: [
          {
            label: 'project.list.filter.date.startdate',
            value: null,
            key: 'startDate',
          },
          {
            label: 'project.list.filter.date.enddate',
            value: null,
            key: 'endDate',
          },
        ],
      },
    ];
  }

  filterByDate(startDate: Date | null, endDate: Date | null) {
    if (this.surveys) {
      this.filteredSurveys = this.surveys.filter((item) => {
        const itemDate = new Date(item.createdAt);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        if (start && end) {
          return itemDate >= start && itemDate <= end;
        } else if (start) {
          return itemDate >= start;
        } else if (end) {
          return itemDate <= end;
        }

        return true;
      });
    }
  }

  onFilterChanged(event: any) {
    const filter =
      event.customFilters
        .find(
          (f) =>
            f.title === 'projects.detail.feedback.list.filter.placeholder' &&
            f.type === CustomFilterType.CHECKBOX,
        )
        ?.filters.filter((filter) => filter.value)
        .map((filter) => filter.key) || [];

    const dateFilters = event.customFilters.find(
      (f) =>
        f.title === 'project.list.filter.date.title' &&
        f.type === CustomFilterType.DATE,
    );

    const startDate = dateFilters?.filters.find(
      (f) => f.key === 'startDate',
    )?.value;
    const endDate = dateFilters?.filters.find(
      (f) => f.key === 'endDate',
    )?.value;

    this.filterByDate(startDate, endDate);

    const sortPrefix = event.sort?.direction === 'desc' ? '-' : '';
    this.filterForm.patchValue({
      search: event.search,
      order: sortPrefix + event.sort?.field,
      filter: filter,
    });
  }

  getStatusClass(survey: Survey): string {
    switch (survey.status) {
      case SurveyStatus.ACTIVE:
        return this.version === 1 ? 'green' : 'label-green';
      case SurveyStatus.INACTIVE:
        return this.version === 1 ? 'orange' : 'label-black';
    }
  }

  changeSortOrder(event) {
    this.orderBy = event.target.value;
  }

  createFilterForm() {
    this.filterForm = this.formBuilder.group({
      search: [''],
      filter: [[SurveyStatus.ACTIVE, SurveyStatus.INACTIVE]],
      order: ['-createdAt'],
    });
  }

  private async load() {
    this.surveys = await this.surveyService.listByProject(this.project);
    this.filteredSurveys = this.surveys;
  }

  async deleteSurvey(survey: Survey): Promise<void> {
    await this.surveyService.remove(survey);
    this.load();
  }
}
